import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

import * as serviceWorker from "./serviceWorker";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
    colors: {
        Containerbg:       "#FFFFFF33",
        TableHeaderbg:     "#FFFFFF80",
        TableCellbg:       "#FFFFFF33",
        BoxHeaderbg:       "#FFFFFF4D",
        InputBoxbg:        "#FFFFFF4D",
        PlaceHolderColor : "#666666",
        HeaderFillbg :     "#2647B0",
        TextColor1 :       "#0B389E"
    },
  })

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <App />
    </ChakraProvider>
  </BrowserRouter>
);

serviceWorker.register("serviceWorker.js");
