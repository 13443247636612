import React, { useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Link,
  Text,
  useColorModeValue,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  useBreakpointValue,
  Collapse,
  VStack,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";

interface RouteType {
  layout: string;
  path: string;
  name: string;
  children?: RouteType[];
  hidden?: boolean;
}

export const SubHeader = ({ routes }: { routes: RouteType[] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
  const isMobile = useBreakpointValue({ base: true, md: false });

  const activeBg = useColorModeValue("#2647B0", "blue.200");
  const inactiveBg = useColorModeValue("transparent", "transparent");
  const activeColor = useColorModeValue("white", "gray.800");
  const inactiveColor = useColorModeValue("#0B389E", "#0B389E");

  const isActiveRoute = (routePath: string) => {
    return location.pathname.includes(routePath);
  };

  const processedRoutes = routes;

  const handleLinkClick = (route: RouteType) => {
    if (route.children) {
      setOpenMenus((prev) => ({ ...prev, [route.path]: !prev[route.path] }));
    } else {
      navigate(`${route.layout}/${route.path}`);
      if (isMobile) setIsOpen(false);
    }
  };

  const renderDesktopLinks = (routes: RouteType[], level = 0) =>
    routes.map((route, index) => {
      if (route.hidden) return null;
      return (
        <Box
          key={index}
          flex={["1 0 100%", "1 0 auto"]}
          maxW={["100%", "200px"]}
          position="relative"
          zIndex="sticky"
        >
          <Flex
            align="center"
            justify="space-between"
            onClick={() => handleLinkClick(route)}
            cursor="pointer"
            bg={isActiveRoute(route.path) ? activeBg : inactiveBg}
            color={isActiveRoute(route.path) ? activeColor : inactiveColor}
            p={3}
            transition="all 0.3s"
            _hover={{
              bg: activeBg,
              color: activeColor,
            }}
          >
            <Text
              fontSize={20}
              fontWeight={600}
              lineHeight={5}
              fontFamily={"Poppins"}
            >
              {route.name}
            </Text>
            {route.children &&
              (openMenus[route.path] ? <ChevronUpIcon /> : <ChevronDownIcon />)}
          </Flex>
          {route.children && openMenus[route.path] && (
              <Box
                position="absolute"
                top="100%"
                left="0"
                right="0"
                bg="white"
                boxShadow="md"
              >
                {route.children.map((child, childIndex) => {
                  if (child.hidden) return null;
                  return (
                    <Link
                      key={childIndex}
                      as={RouterLink}
                      to={`${route.path}/${child.path}`}
                      _hover={{ textDecoration: "none" }}
                      w="100%"
                      onClick={() => setOpenMenus({})}
                    >
                      <Flex
                        align="center"
                        p={2}
                        bg={
                          isActiveRoute(`${route.path}/${child.path}`)
                            ? activeBg
                            : inactiveBg
                        }
                        color={
                          isActiveRoute(`${route.path}/${child.path}`)
                            ? activeColor
                            : inactiveColor
                        }
                        transition="all 0.3s"
                        _hover={{
                          bg: activeBg,
                          color: activeColor,
                        }}
                      >
                        <Text
                          fontSize={18}
                          fontWeight={400}
                          lineHeight={5}
                          fontFamily={"Poppins"}
                        >
                          {child.name}
                        </Text>
                      </Flex>
                    </Link>
                  );
                })}
              </Box>
          )}
        </Box>
      );
    });

  const renderMobileLinks = (routes: RouteType[], level = 0) =>
    routes.map((route, index) => {
      if (route.hidden) return null;
      return (
        <Box key={index} w="100%">
          <Flex
            align="center"
            justify="space-between"
            onClick={() => handleLinkClick(route)}
            cursor="pointer"
            bg={isActiveRoute(route.path) ? activeBg : inactiveBg}
            color={isActiveRoute(route.path) ? activeColor : inactiveColor}
            p={3}
            transition="all 0.3s"
            _hover={{
              bg: activeBg,
              color: activeColor,
            }}
          >
            <Text
              fontSize={20 - level * 2}
              fontWeight={500}
              lineHeight={5}
              fontFamily={"Poppins"}
            >
              {route.name}
            </Text>
            {route.children &&
              (openMenus[route.path] ? <ChevronUpIcon /> : <ChevronDownIcon />)}
          </Flex>
          {route.children && openMenus[route.path] && (
              <VStack align="stretch" pl={4} bg="gray.50">
                {route.children.map((child, childIndex) => {
                  if (child.hidden) return null;
                  return (
                    <Link
                      key={childIndex}
                      as={RouterLink}
                      to={`${route.layout}/${route.path}/${child.path}`}
                      _hover={{ textDecoration: "none" }}
                      onClick={() => {
                        setIsOpen(false);
                        setOpenMenus({});
                      }}
                    >
                      <Flex
                        align="center"
                        p={2}
                        bg={
                          isActiveRoute(`${route.path}/${child.path}`)
                            ? activeBg
                            : inactiveBg
                        }
                        color={
                          isActiveRoute(`${route.path}/${child.path}`)
                            ? activeColor
                            : inactiveColor
                        }
                        transition="all 0.3s"
                        _hover={{
                          bg: activeBg,
                          color: activeColor,
                        }}
                      >
                        <Text
                          fontSize={18}
                          fontWeight={400}
                          lineHeight={5}
                          fontFamily={"Poppins"}
                        >
                          {child.name}
                        </Text>
                      </Flex>
                    </Link>
                  );
                })}
              </VStack>
          )}
        </Box>
      );
    });

  return (
    <>
      {isMobile ? (
        <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
            onClick={() => setIsOpen(!isOpen)}
          />
          <MenuList maxH="80vh" overflowY="auto">
            <VStack align="stretch" spacing={0}>
              {renderMobileLinks(processedRoutes)}
            </VStack>
          </MenuList>
        </Menu>
      ) : (
        <Flex
          direction={["column", "row"]}
          wrap="wrap"
          justify="flex-start"
          align="stretch"
          px={10}
        >
          {renderDesktopLinks(processedRoutes)}
        </Flex>
      )}
    </>
  );
};