import React, { useEffect, useState } from "react";
import { Box, Container, Flex, Grid, GridItem, Icon } from "@chakra-ui/react";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useGameStore } from "store/store";
import Header from "layouts/main/Components/mainheader";
import LoginComponent from "./Components/workshopLogin";
import workshoproutes from "workshoproute";
import { SubHeader } from "./Components/subheader";
import { ProtectedRoute } from "components/protectedroutes/protectedroutes";
import { toast } from "react-toastify";
import { usePusher } from "context/pusher/PusherContext";
import FooterCustom from "components/footer/FooterCustom";
import PageNotFound from "components/pages/PageNotFound";
import { RiRobot2Line } from "react-icons/ri";
import { checkChatbot } from "helper/checkChatbot";
import { Transition } from "@headlessui/react";
import Chatbot from "views/chatbot/components/chatbot";

const getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop.children) {
      return (
        <Route path={prop.path} key={key}>
          <Route
            index
            element={<Navigate to={prop.children[0].path} replace />}
          />
          {prop.children.map((child, childKey) => (
            <Route
              path={child.path}
              element={child.component}
              key={`${key}-${childKey}`}
            />
          ))}
        </Route>
      );
    } else {
      return (
        <Route
          path={prop.path}
          element={
            prop.protected ? (
              <ProtectedRoute>{prop.component}</ProtectedRoute>
            ) : (
              prop.component
            )
          }
          key={key}
        />
      );
    }
  });
};

export default function WorkshopLayout() {
  const { Workshop } = useGameStore();
  const pusher = usePusher();
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const location = useLocation();
  function clearData() {
    pusher.disconnect.bind();
    navigate(`/play/${Workshop.acronym}`);
  }

  useEffect(() => {
    function logoutEventCallback(data) {
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            {data?.title}
          </div>
          <div className="font-Helvetica text-base tracking-wide">
            {data?.message}
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          onClose: clearData,
          theme: "colored",
        }
      );
    }

    function childEventCallback(data) {
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            {data?.title}
          </div>
          <div className="font-Helvetica text-base tracking-wide">
            {data?.message}
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 300000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }

    let subscriber = `team_${Workshop?.team?.id}`;

    if (subscriber) {
      const channel = pusher.subscribe(subscriber);
      channel.bind("logout-event", logoutEventCallback);
      channel.bind("my-event", childEventCallback);

      return () => {
        channel.unbind("logout-event", logoutEventCallback);
        channel.unbind("my-event", childEventCallback);

        pusher.unsubscribe(subscriber);
      };
    } else {
      console.error("No valid team name found for subscription");
    }
  }, [pusher, Workshop, clearData]);

  useEffect(() => {
    closeModal();
  }, [location]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Flex id="layout" flexDirection="column" minHeight="100vh">
      {Workshop && (
        <Flex
          id="header"
          flexDirection="column"
          position="sticky"
          top={0}
          zIndex={999}
          bg="white"
        >
          <Box as="header">
            <Header />
          </Box>
          <Grid
            as="nav"
            templateColumns="repeat(12, 1fr)"
            gap={4}
            className="shadow-[inset_0_8px_10px_-5px_#05369f4e]"
          >
            <GridItem colSpan={11}>
              <SubHeader routes={workshoproutes} />
            </GridItem>
            {checkChatbot(`/${pathname.split("/")[2]}`) && (
              <GridItem
                as={Flex}
                colSpan={1}
                justifyContent={"flex-end"}
                pr={10}
                alignItems={"center"}
              >
                <Icon
                  as={RiRobot2Line}
                  boxSize={6}
                  onClick={openModal}
                  cursor={"pointer"}
                />
              </GridItem>
            )}
          </Grid>
        </Flex>
      )}
      <Flex
        as="main"
        flex={1}
        overflow="auto"
        bgGradient="linear(to-r, #F04E4A,#0537A0)"
      >
        <Container
          id="main-container"
          maxW="3000px"
          py={8}
          display="flex"
          flexDirection="column"
          flex={1}
        >
          <Routes>
            {getRoutes(workshoproutes)}
            <Route path="/:acronym" element={<LoginComponent />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Container>
      </Flex>
      <Box as="footer">
        <FooterCustom />
      </Box>
      <Transition appear show={isOpen}>
        <Box position="fixed" top={10} right={0} zIndex={999}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Chatbot onClose={closeModal} />
          </Transition.Child>
        </Box>
      </Transition>
    </Flex>
  );
}
