import React, { useState, useCallback, useEffect } from "react";
import { useDropzone, FileRejection, DropzoneOptions } from "react-dropzone";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  VStack,
  Image,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";
import Select from "react-select";
import uploadImage from "../../../../assets/systemadmin/upload.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosClient from "config/apiClient";
import { useCommonToast } from "components/toast/toast";
import Loader from "components/loader/loader";

interface FileUploadProps {
  onFileUpload: (file: File) => void;
  label: string;
  filename?: string;
  error?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  label,
  filename,
  error,
}) => {
  const showToast = useCommonToast();
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles[0]) {
        onFileUpload(acceptedFiles[0]);
      }
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file) => {
          let errorMessage = "File upload failed.";
          if (file.errors[0]?.code === "file-too-large") {
            errorMessage = "File is too large. Maximum size is 5MB.";
          } else if (file.errors[0]?.code === "file-invalid-type") {
            errorMessage = "Invalid file type. Please upload a CSV file.";
          }
          showToast("Error", errorMessage, "error");
        });
      }
    },
    [onFileUpload, showToast]
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    multiple: false,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    minSize: 0,
    maxSize: 5242880,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  const rootProps: BoxProps = {
    ...getRootProps(),
    p: 4,
    border: "2px dashed",
    borderColor: isDragActive ? "gray.700" : error ? "red.500" : "#09389F",
    borderRadius: "md",
    textAlign: "center",
    cursor: "pointer",
    width: { base: "100%", md: "60%" },
    h: "100%",
    bg: "#0A389F1A",
  };

  const flexProps: FlexProps = {
    justifyContent: "center",
    alignItems: "center",
    h: "100%",
    flexDirection: "column",
  };

  return (
    <Flex flexDirection={{ base: "column", md: "row" }} flex={1} h="80%">
      <Box {...rootProps}>
        <input {...getInputProps()} />
        <Flex {...flexProps}>
          {filename ? (
            <>
              <Image
                src={uploadImage}
                h="30%"
                w="30%"
                objectFit="contain"
                alt="Upload"
              />
              <Text>File Uploaded : {filename}</Text>
            </>
          ) : (
            <>
              <Image
                src={uploadImage}
                h="30%"
                w="30%"
                objectFit="contain"
                alt="Upload"
              />
              {isDragActive ? (
                <Text>Drop the CSV file here</Text>
              ) : (
                <Text>{label}</Text>
              )}
            </>
          )}
        </Flex>
      </Box>
      <Box
        display="flex"
        width={{ base: "100%", md: "40%" }}
        h="100%"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Text>Supported Formats : CSV</Text>
        <Text>Maximum Size : 5 MB</Text>
      </Box>
    </Flex>
  );
};

interface FormErrors {
  configName?: string;
  file1?: string;
  "game-name"?: string;
  "file-type-conf"?: string;
}

const CreateConfigurationForm: React.FC = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [configName, setConfigName] = useState<string>("");
  const [file1, setFile1] = useState<File | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});
  const [selectedConfigurations, setSelectedConfigurations] = useState<
    Record<string, any>
  >({});
  const queryParam = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const showToast = useCommonToast();
  const { type } = location.state || {};
  const handleFile1Upload = (file: File) => setFile1(file);
  const isEditable = location.pathname.includes("edit");
  const gameDataType = [
    {
      label: "Game 1",
      value: "Game1",
      type: [
        { label: "Price Elasticity", value: "PriceElasticity" },
        { label: "Time to Patrons", value: "TimetoPatrons" },
      ],
    },
    {
      label: "Game 2",
      value: "Game2",
      type: [
        { label: "Price Elasticity", value: "PriceElasticity" },
        { label: "Time to Patrons", value: "TimetoPatrons" },
      ],
    },
    {
      label: "Game 3",
      value: "Game3",
      type: [
        { label: "Price Elasticity", value: "PriceElasticity" },
        { label: "Time to Patrons", value: "TimetoPatrons" },
      ],
    },
  ];

  const formFields = [
    {
      label: "Game",
      id: "game-name",
      helperText: "Select the name of the game",
      options: gameDataType.map(({ label, value }) => ({ label, value })),
    },
    {
      label: "File Type",
      id: "file-type-conf",
      helperText: "Select the type of configuration",
      options: [],
    },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "30vh",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#2647B0" : state.isSelected ? "#2647B080" : provided.backgroundColor,
      color: state.isFocused ? "white" : provided.color,
      '&:hover': {
        backgroundColor: "#2647B0",
        color: "white",
      },
    }),
  };

  const resetForm = () => {
    setConfigName("");
    setFile1(null);
    setErrors({});
    setSelectedConfigurations({});
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!configName.trim()) {
      newErrors.configName = "Configuration name is required";
    }
    if (!file1) {
      newErrors.file1 = "Configuration file is required";
    }
    formFields.forEach((field) => {
      if (!selectedConfigurations[field.id]) {
        newErrors[field.id] = `${field.label} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadConfiguration = async (configData: FormData) => {
    let resp;
    if (isEditable) {
      configData.append("id", queryParam.id);
    }
    if (configData.get("type") === "PriceElasticity") {
      resp = await axiosClient[isEditable ? "put" : "post"](
        `flow/metrics/price/elasticity/${
          isEditable ? `${queryParam.id}/` : ""
        }`,
        configData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } else {
      resp = await axiosClient[isEditable ? "put" : "post"](
        `flow/metrics/patron/${isEditable ? `${queryParam.id}/` : ""}`,
        configData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    return resp.data;
  };

  const handleSubmit = async (): Promise<void> => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("name", configName.trim());
        if (file1) formData.append("file", file1);
        formData.append("game", selectedConfigurations["game-name"].value);
        formData.append("type", selectedConfigurations["file-type-conf"].value);
        const response = await uploadConfiguration(formData);
        showToast("Success", response.message, "success");
        resetForm();
        navigate("/configuration/list")
      } catch (error: any) {
        showToast("Error", error.response?.data?.error || error.response?.data?.message, "error");
      } finally {
        setIsLoading(false);
      }
    } else {
      showToast("Error", "Please fill in all required fields.", "error");
    }
  };

  const fetchConfigurationDetails = async () => {
    try {
      const userData = (
        await axiosClient.get(
          `/flow/metrics/get/${
            type === "Time to Patron" ? "patrons" : "elasticity"
          }/?id=${queryParam.id}`
        )
      ).data;
      setConfigName(userData[0].name);
      setFile1((prev) => ({
        ...prev,
        name: userData[0].uploaded_file_name,
      }));
      setSelectedConfigurations((prev) => ({
        ...prev,
        "game-name": {
          label: "Game 3",
          value: "Game3",
        },
        "file-type-conf": {
          label: type,
          value: type.replace(/\s/g, ""),
        },
      }));
    } catch (error) {
      throw error;
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    resetForm();
  }, [isEditable]);

  useEffect(() => {
    if (isEditable) {
      setIsInitialLoading(true);
      fetchConfigurationDetails();
    }
  }, []);

  const handleGameChange = (selectedOption, fieldId) => {
    setSelectedConfigurations((prev) => {
      const newConfig = { ...prev, [fieldId]: selectedOption };
      if (fieldId === "game-name") {
        newConfig["file-type-conf"] = null; // Reset file type when game changes
      }
      return newConfig;
    });
  };

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Box
          id="addconfiguration-form"
          overflowX="auto"
          bg={"#FFFFFF10"}
          borderRadius="20px"
          display="flex"
          flexDirection="column"
          flex={1}
          fontFamily="Poppins"
          mx={{ base: "0vw", md: "18vw" }}
        >
          <Flex direction="column" flex={1}>
            <Flex
              bg="TableHeaderbg"
              justify="space-between"
              align="center"
              py="10px"
              px="20px"
              textColor="#0B389E"
              fontWeight={600}
              fontSize="18px"
            >
              {isEditable ? "Edit " : "Create "} Configuration
            </Flex>
            <Flex
              bg="Containerbg"
              flex={1}
              py="10px"
              px="20px"
              direction="column"
              justifyContent="space-between"
            >
              <VStack id="form-box" spacing={4} align="stretch" h="100%">
                <FormControl isRequired isInvalid={!!errors.configName}>
                  <FormLabel fontWeight={500}>Configuration Name</FormLabel>
                  <Input
                    placeholder="Enter the Configuration name"
                    bg="InputBoxbg"
                    value={configName}
                    _placeholder={{ color: "PlaceHolderColor" }}
                    onChange={(e) => setConfigName(e.target.value)}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        configName: "",
                      }));
                    }}
                  />
                  <FormHelperText
                    color={errors.configName ? "red.500" : "inherit"}
                  >
                    {/* {errors.configName ||
                      "Enter the Configuration name you want to create"} */}
                  </FormHelperText>
                </FormControl>
                {formFields.map((field) => (
                  <FormControl
                    key={field.id}
                    isRequired
                    isInvalid={!!errors[field.id]}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        [field.id]: "",
                      }));
                    }}
                  >
                    <FormLabel>{field.label}</FormLabel>
                    <Select
                      id={`select-${field.id}`}
                      classNamePrefix="select"
                      name={field.id}
                      isLoading={isLoading}
                      isClearable={true}
                      isSearchable={true}
                      options={
                        field.id === "file-type-conf" &&
                        selectedConfigurations["game-name"]
                          ? gameDataType.find(
                              (game) =>
                                game.value ===
                                selectedConfigurations["game-name"].value
                            )?.type || []
                          : field.options
                      }
                      styles={customStyles}
                      value={selectedConfigurations[field.id] || null}
                      onChange={(selectedOption) =>
                        handleGameChange(selectedOption, field.id)
                      }
                      isDisabled={
                        field.id === "file-type-conf" &&
                        !selectedConfigurations["game-name"]
                        || isEditable
                      }
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                    />
                    <FormHelperText
                      color={errors[field.id] ? "red.500" : "inherit"}
                    >
                      {errors[field.id] || field.helperText}
                    </FormHelperText>
                  </FormControl>
                ))}
                <Flex flex={1}>
                  <FormControl
                    isRequired
                    isInvalid={!!errors.file1}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        file1: "",
                      }));
                    }}
                  >
                    <FileUpload
                      onFileUpload={handleFile1Upload}
                      label="Drag and drop or click to upload"
                      filename={file1?.name}
                      error={!!errors.file1}
                    />
                    {errors.file1 && (
                      <FormHelperText color="red.500">
                        {errors.file1}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Flex>
              </VStack>
              <Flex
                id="button-box"
                justifyContent="flex-end"
                alignItems="flex-end"
                gap={3}
                mt={4}
              >
                <Button
                  borderRadius="8px"
                  bg="white"
                  textColor="#0B389E"
                  border="2px"
                  borderColor="#5C85DC"
                  onClick={() => navigate("/configuration/list")}
                >
                  Cancel
                </Button>
                <Button
                  borderRadius="8px"
                  bgGradient="linear(to-b, #5C86DC,#2647B0)"
                  _hover={{ bgGradient: "linear(to-b, #4a86ff,#233d97)" }}
                  textColor="white"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  Confirm
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default CreateConfigurationForm;
