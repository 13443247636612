export const checkResourcePermission = (permissionList, resourceName) => {
  const allowedResources = permissionList.resourcesMap.map(
    (resource) => resource.resourceName
  );
  const isAllowed = allowedResources.includes(resourceName);
  return isAllowed;
};

export function checkSubResourcePermission(
  permissionList,
  resourceName,
  permission
) {
  const resource = permissionList.resourcesMap.find(
    (r) => r.resourceName === resourceName
  );
  if (resource) {
    return resource.permissionList.includes(permission);
  }
  return false;
}
