interface Item {
    modified_at?: string;
    created_at: string;
  }
  
  export function sortByModifiedDate(list: Item[], order: 'asc' | 'desc' = 'desc'): Item[] {
    return list.sort((a: Item, b: Item) => {
      const dateA = a.modified_at ? new Date(a.modified_at) : new Date(a.created_at);
      const dateB = b.modified_at ? new Date(b.modified_at) : new Date(b.created_at);
      
      return order === 'desc' ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
    });
  }