import { useState } from "react";
import { useGameStore } from "../../../store/store";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Patron } from "../../../store/game1A/game1ASlice";
import { toast } from "react-toastify";
import { Direction, Range } from "react-range";
import {
  ArrowDownIcon,
  ArrowsUpDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { ImArrowLeft } from "react-icons/im";
export default function Game1AStep2() {
  const [isSorted, setIsSorted] = useState(false);
  const [isStep2FirstGroupingInProgress, setIsStep2FirstGroupingInProgress] =
    useState(false);
  const [isStep2FirstGroupingDone, setIsStep2FirstGroupingDone] =
    useState(false);
  const [isStep2SecondGroupingDone, setIsStep2SecondGroupingDone] =
    useState(false);
  const [showHelp, setShowHelp] = useState(true);

  const [secondGroupCursor, setSecondGroupCursor] = useState(0);
  const patronList = useGameStore((state) => state.game1AStep1PatronList);
  const [sliderValues, setSliderValues] = useState<Array<number>>([0, 0]);
  const sortPatronListBy = useGameStore((state) => state.sortPatronListBy);
  const step1GroupIds = useGameStore((state) => state.step1GroupIds);
  const step2FirstGroup = useGameStore((state) => state.step2FirstGroupIds);
  const step2SecondGroup = useGameStore((state) => state.step2SecondGroupIds);
  const setStep2FirstGroup = useGameStore((state) => state.setStep2FirstGroup);
  const setStep2SecondGroup = useGameStore(
    (state) => state.setStep2SecondGroup
  );
  const incrementCurrentStep = useGameStore(
    (state) => state.incrementCurrentStep
  );
  const [parent] = useAutoAnimate();
  const [isBlurActive, setIsBlurActive] = useState(false);
  const getCellBackground = (id: Patron["id"]) => {
    if (isStep2FirstGroupingInProgress) {
      if (step2FirstGroup.includes(id)) return "bg-color3";
    }
    if (!isStep2FirstGroupingDone && !isStep2SecondGroupingDone)
      return "bg-[#C9A0CA26]";
    if (isStep2FirstGroupingDone && !isStep2SecondGroupingDone) {
      return step2FirstGroup.includes(id) ? "bg-color3" : "bg-[#C9A0CA26]";
    }
    if (isStep2FirstGroupingDone && isStep2SecondGroupingDone) {
      if (step2FirstGroup.includes(id)) return "bg-color3";
      if (step2SecondGroup.includes(id)) return "bg-color4";
      return "bg-color5";
    }
  };

  return (
    <div className="relative flex h-full w-2/3 flex-col items-center rounded-lg bg-white text-center">
      <div className="mt-4 text-lg font-bold text-[#26337C]">
        {!isSorted ? (
          "If you have one more column of data, how would you segment the patrons?"
        ) : !isStep2FirstGroupingDone ? (
          "Select a row or drag the arrow up and down to group patrons by last year"
        ) : !isStep2SecondGroupingDone ? (
          "Select another row to create a new group"
        ) : (
          <>Good Job! Now click &quot;Next&quot; to continue</>
        )}
        <button
          className="absolute right-2 top-5 z-50"
          onClick={() => {
            setShowHelp(true);
          }}
        >
          <InformationCircleIcon className="h-6 w-6 text-[#2B3F6C] " />
        </button>
      </div>
      <div className="flex flex-col justify-center">
        <div className="border-black mt-2 flex animate-animateFadeIn flex-row justify-center rounded-[6px]  border-2 bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7]">
          <h3 className="flex h-10 w-[90px] items-center justify-center rounded-l-[6px] text-center text-[14px] font-semibold">
            Patrons
          </h3>
          <h3 className="border-black flex h-10 w-[120px] items-center justify-center border-l-2 border-r-2 text-center  text-[14px] font-semibold">
            Previous Friday Sale
          </h3>
          <button
            id="sortButton"
            disabled={isSorted}
            onClick={(e) => {
              e.preventDefault();
              sortPatronListBy("lastYear");
              setIsSorted(true);
              setStep2FirstGroup([]);
              setStep2SecondGroup([]);
              setIsStep2FirstGroupingDone(false);
              setIsStep2SecondGroupingDone(false);
              setSliderValues([0, 0]);
            }}
            className="relative flex h-10  w-[120px] items-center justify-center rounded-r-[6px] text-[14px] font-semibold"
          >
            Total Last Year Sale
            {isSorted ? (
              <ArrowDownIcon className="absolute bottom-1 right-2 h-4 w-4" />
            ) : (
              <ArrowsUpDownIcon className="absolute bottom-1 right-2 h-4 w-4" />
            )}
          </button>
        </div>
        <div className="relative flex justify-center">
          <ul
            ref={parent}
            className="mb-10 mt-2 flex animate-animateFadeIn flex-col gap-1"
          >
            {patronList.map((patron, index) => (
              <div
                id={`patron${patron.id}`}
                key={patron.id}
                className="flex flex-row rounded-[6px] border border-[#DDE8FF] text-sm"
              >
                <div className="flex h-[20px] w-[90px] items-center justify-center bg-[#EAEEFF85]">
                  {patron.name}
                </div>
                <div
                  className={`flex h-[20px] w-[120px] items-center justify-center ${
                    step1GroupIds.includes(patron.id)
                      ? "bg-color1"
                      : "bg-color2"
                  }`}
                >
                  {patron.actualDaily}
                </div>
                <div
                  className={`flex h-[20px] w-[120px] items-center justify-center rounded-r-[6px] ${
                    isSorted ? "hover:cursor-pointer" : ""
                  } ${getCellBackground(patron.id)}`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isSorted) {
                      return;
                    }
                    if (isStep2FirstGroupingDone && isStep2SecondGroupingDone) {
                      return;
                    }
                    if (!isStep2FirstGroupingDone) {
                      if (index >= patronList.length - 2) {
                        setIsBlurActive(true);
                        toast.error(
                          <>
                            <div className="font-Helvetica text-lg font-bold tracking-wide">
                              Incorrect segmentation
                            </div>
                            <div className="font-Helvetica text-sm tracking-wide">
                              Groups should have atleast one element!
                            </div>
                          </>,
                          {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            onClose: () => {
                              setIsBlurActive(false);
                            },
                          }
                        );
                        return;
                      }
                      setStep2FirstGroup(
                        patronList
                          .slice(0, index + 1)
                          .map((patron) => patron.id)
                      );
                      setSliderValues([index, index + 1]);
                      setSecondGroupCursor(index + 1);
                      setIsStep2FirstGroupingDone(true);
                      return;
                    }
                    if (!isStep2SecondGroupingDone) {
                      if (index === patronList.length - 1) {
                        setIsBlurActive(true);
                        toast.error(
                          <>
                            <div className="font-Helvetica text-lg font-bold tracking-wide">
                              Incorrect segmentation
                            </div>
                            <div className="font-Helvetica text-sm tracking-wide">
                              Groups should have atleast one element!
                            </div>
                          </>,
                          {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            onClose: () => {
                              setIsBlurActive(false);
                            },
                          }
                        );
                        return;
                      }
                      setStep2SecondGroup(
                        patronList
                          .slice(secondGroupCursor, index + 1)
                          .map((patron) => patron.id)
                      );
                      setSliderValues([sliderValues[0], index]);
                      setIsStep2SecondGroupingDone(true);
                    }
                  }}
                >
                  {patron.lastYear}
                </div>
              </div>
            ))}
          </ul>
          <Range
            min={0}
            max={18}
            values={sliderValues}
            allowOverlap={false}
            onChange={(values) => {
              if (values[0] >= patronList.length - 2) return;
              if (values[0] === values[1]) return;
              if (!isStep2FirstGroupingDone) {
                setStep2FirstGroup(
                  patronList.slice(0, values[0] + 1).map((patron) => patron.id)
                );
                setIsStep2FirstGroupingInProgress(true);
                setSliderValues([values[0], sliderValues[1]]);
                setSecondGroupCursor(values[0] + 1);
                return;
              }
              setSliderValues(values);
              setStep2FirstGroup(
                patronList.slice(0, values[0] + 1).map((patron) => patron.id)
              );
              setStep2SecondGroup(
                patronList
                  .slice(values[0] + 1, values[1] + 1)
                  .map((patron) => patron.id)
              );
              setIsStep2SecondGroupingDone(true);
              setShowHelp(false);
            }}
            onFinalChange={(values) => {
              if (!isStep2FirstGroupingDone) {
                setIsStep2FirstGroupingInProgress(false);
                setIsStep2FirstGroupingDone(true);
                setStep2FirstGroup(
                  patronList.slice(0, values[0] + 1).map((patron) => patron.id)
                );
                setSliderValues([values[0], values[0] + 1]);
                return;
              }
              setStep2SecondGroup(
                patronList
                  .slice(values[0] + 1, values[1] + 1)
                  .map((patron) => patron.id)
              );
              setIsStep2SecondGroupingDone(true);
              setSliderValues(values);
            }}
            direction={Direction.Down}
            renderTrack={({ props, children }) => (
              <div
                className="absolute -right-5 top-5 z-40 h-[467px]"
                {...props}
                style={{
                  ...props?.style,
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props, index }) => {
              if (!isStep2FirstGroupingDone) {
                return index === 0 ? (
                  <ImArrowLeft
                    {...props}
                    className="slider-element1 h-9 w-9 text-[#2B3F6C] !outline-none"
                  />
                ) : null;
              }
              return (
                <ImArrowLeft
                  {...props}
                  className="slider-element2 h-8 w-8 text-[#2B3F6C] !outline-none"
                />
              );
            }}
          />
        </div>
      </div>
      {showHelp ? (
        <div className="absolute right-[5%] top-[25%] z-50 flex h-36 animate-animateFadeIn sm:w-48 sm-max:w-32 md:w-60 lg:w-72 xl:w-80">
          <div className="help ipad11-max:h-38 absolute right-[12%] top-[50%] flex h-fit w-[13vw] flex-col items-center justify-between gap-2 rounded-[20px] p-3">
            <span>
              {isStep2FirstGroupingDone
                ? "Great!! Now select another row or drag the second arrow up and down to create the other groups"
                : "Select a row or drag the arrow up and down to group patrons by last year"}
            </span>
            <button
              className="button-class rounded-md px-5 py-[2px] text-sm text-white underline-offset-2 hover:bg-[#3072D6]"
              onClick={() => {
                setShowHelp(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      ) : null}

      <div className="flex flex-row justify-between">
        {isBlurActive && (
          <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"></div>
        )}
      </div>
      {!isStep2FirstGroupingDone ? null : !isStep2SecondGroupingDone ? ( // </button> //   Sort patrons by last year // > //   }} //     setIsSorted(true); //     sortPatronListBy("lastYear"); //     e.preventDefault(); //   onClick={(e) => { //   type="button" //   className={buttonStyle} // <button
        <>
          <button
            className={
              "button-class absolute bottom-1 left-10 mx-5 mb-2 flex rounded-md  border px-5 py-[3px] text-base text-white  duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
            }
            onClick={(e) => {
              e.preventDefault();
              setStep2FirstGroup([]);
              setIsStep2FirstGroupingDone(false);
              setSliderValues([0, 0]);
            }}
          >
            Back
          </button>
        </>
      ) : (
        <>
          <button
            className="button-class absolute bottom-1 left-10 mx-5 mb-2 flex rounded-md  px-5 py-[3px] text-base text-white  duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
            onClick={(e) => {
              e.preventDefault();
              setStep2SecondGroup([]);
              setIsStep2SecondGroupingDone(false);
              setSliderValues([
                step2FirstGroup.length - 1,
                step2FirstGroup.length,
              ]);
            }}
          >
            Back
          </button>
        </>
      )}
      <>
        <button
          className={`${
            isStep2FirstGroupingDone ? `absolute bottom-1 right-10 ` : ` `
          }z-50 button-class mb-2 flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6] disabled:cursor-not-allowed disabled:grayscale-[70%]`}
          onClick={(e) => {
            if (
              !isStep2SecondGroupingDone ||
              !isSorted ||
              sliderValues[0] !== 8 ||
              sliderValues[1] !== 14
            ) {
              setIsBlurActive(true);
              toast.error(
                <>
                  <div className="font-Helvetica text-lg font-bold tracking-wide">
                    Incorrect segmentation
                  </div>
                  <div className="font-Helvetica text-sm tracking-wide">
                    Please try again!
                  </div>
                </>,
                {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "colored",
                  onClose: () => {
                    setIsBlurActive(false);
                  },
                }
              );
            } else {
              incrementCurrentStep();
            }
          }}
        >
          Continue
        </button>
      </>
    </div>
  );
}
