import { parse , format, formatISO, parseISO, addMinutes } from 'date-fns';
import moment from 'moment-timezone';

  export const formatDatetime = (dateString) => {
    // console.log(dateString)
    // console.log(moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').format('LLL'))
    return moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss').format('LLL');
  };
  
  export const formatDate = (dateString) => {
    return moment.utc(dateString, 'YYYY-MM-DD 00:00:00').format('LL');
  };

// export const convertUTC = (dateString) => {
//     const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
//     const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
//     return formattedDate;
// };

export const convertUTCMin = (dateString) => {
    const parsedDate = parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
    const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss");
    return formattedDate;
};

export const convertUTCSec = (dateString) => {
    // const parsedDate = parse(dateString, "yyyy-MM-dd'T'HH:mm", new Date());
    return format(dateString, "yyyy-MM-dd'T'HH:mm:ss");
  };

export const timePickerConverter  = (dateString) => {
    const parsedDate = parseISO(dateString.toISOString());
    return format(parsedDate,"yyyy-MM-dd'T'HH:mm");;
};


export const timePickerEdit  = (dateString) => {
const parsedDate = parse(dateString, 'MMM dd, yyyy, h:mm:ss a', new Date())
return format(parsedDate, "yyyy-MM-dd'T'HH:mm")
};

export const convertUTCtoLocal = (utcDateString) => {
    const localDate = new Date(utcDateString);
    return format(localDate, "yyyy-MM-dd'T'HH:mm:ssxxx")
}

export const convertTimeZone = (time,format = 'YYYY-MM-DD HH:mm:ss') => {
    const localTimezone = moment.tz.guess();
    return moment.tz(time, "UTC")
        .tz(localTimezone)
        .format(format);
}

export const convertDate = (time,format = `YYYY-MM-DDTHH:mm:ss`) => {
    const localTimezone = moment.tz.guess();
    return moment.tz(time, "UTC")
        .tz(localTimezone)
        .format(format);
}

export const localTime = () => {
    const localTimezone = moment.tz.guess();
    return moment.tz(localTimezone)._d
}