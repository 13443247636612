import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

const NewFooter: React.FC = () => {
  return (
    <Box
      as="footer"
      width="100%"
      bgGradient="linear(to-r, #F04E4A,#0537A0)"
      color="white"
      pb={2}
    >
      <Flex justifyContent="center" alignItems="center">
        <Text fontSize="sm">
          © {new Date().getFullYear()} RoAI Institute. All rights reserved.
        </Text>
      </Flex>
    </Box>
  );
};

export default NewFooter;
