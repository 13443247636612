import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Image,
  Link,
  VStack,
  HStack,
  Box,
} from "@chakra-ui/react";

const QRLinkModal = ({ isOpen, onClose, qrLink, normalLink, title }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title || "QR Code and Link"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={4}
            boxShadow="md"
          >
            <VStack spacing={4} align="stretch">
              <HStack justifyContent="center">
                <Image
                  boxSize="150px"
                  objectFit="cover"
                  src={qrLink}
                  alt="QR Code"
                  borderRadius="8px"
                />
              </HStack>
              <VStack align="start" spacing={2}>
                <Text fontWeight="bold">Link:</Text>
                <Link href={normalLink} color="blue.500" isExternal>
                  {normalLink}
                </Link>
              </VStack>
            </VStack>
          </Box>
        </ModalBody>
        {/* <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default QRLinkModal;
