import { StateCreator } from "zustand";

export interface WorkshopData{
    acronym:string,
    workshop_id: number,
    tenant_id: number,
    team_name: string,
    configs: {
        id: number,
        tenant_id: number,
        tenant__name: string,
        workshop_id: number,
        workshop__name: string,
        patron_id: number,
        patron__name: string,
        price_elasticity_id: number,
        price_elasticity__name: string,
        game_type: string
    },
    team: {
        id: number,
        user_id: number,
        name: string,
        description: string,
        date: string
    }
}

export interface WorkShopSlice {
  Workshop: WorkshopData;
  setWorkshop: (data: WorkshopData) => void;
}

type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

export const createWorkshopSlice: ImmerStateCreator<WorkShopSlice> = (set) => ({
  Workshop: null,
  setWorkshop: (data) =>
    set((state) => {
      state.Workshop = data;
    }),
});
