import { useState } from "react";
import { useGameStore } from "../../../store/store";

export default function Game1AStep1Transition() {
  const patronList = useGameStore((state) => state.game1AStep1PatronList);
  const step1GroupIds = useGameStore((state) => state.step1GroupIds);
  const incrementCurrentStep = useGameStore(
    (state) => state.incrementCurrentStep
  );

  const [animate, setAnimate] = useState(false);
  const [Display, setDisplay] = useState(false);
  setTimeout(() => {
    setAnimate(true);
  }, 500);
  setTimeout(() => {
    setDisplay(true);
  }, 1500);

  let top = step1GroupIds.length * 36 + 8 + 99;

  return (
    <div
      className={`relative mt-4 flex h-full min-h-[600px] w-[45%] flex-col items-center rounded-lg bg-white`}
    >
      <div className="mt-2 items-center  justify-center text-center text-[18px] font-bold text-[#26337C]">
        You did it. Here are the 2 patron segments you have created!!
      </div>
      <div className="absolute top-24 flex w-1/3 animate-animateTable1 flex-col">
        <div className="flex flex-row rounded-lg bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7]">
          <h3 className="border-black flex h-10 w-2/5 items-center justify-center rounded-l-[6px] border-2 text-[1vw] font-semibold">
            Patrons
          </h3>
          <h3 className="border-black flex h-10 w-3/5 items-center justify-center rounded-r-[6px] border-2 border-l-0 text-center text-[1vw] font-semibold">
            Last Friday Sale
          </h3>
        </div>
        <ul className="mt-1 flex flex-col gap-1">
          {patronList
            .filter((patron) => step1GroupIds.includes(patron.id))
            .map((patron) => (
              <div
                key={patron.id}
                className="flex flex-row rounded-lg border border-[#DDE8FF] text-[1vw]"
              >
                <div className="flex h-[20px] w-2/5  items-center justify-center rounded-l-[6px] bg-[#EAEEFF85]">
                  {patron.name}
                </div>
                <div className="flex h-[20px] w-3/5  items-center justify-center rounded-r-[6px] bg-color1">
                  {patron.actualDaily}
                </div>
              </div>
            ))}
        </ul>
      </div>
      <ul
        className={`absolute flex flex-col gap-1 w-1/3${
          animate
            ? " translate-x-[52%] animate-animateMoveUp"
            : "animate-animateTable2"
        }`}
        style={{ top: `${top}px` }}
      >
        <div className="flex w-full flex-row rounded-lg bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7] ">
          <h3 className="border-black flex h-10  w-2/5 items-center justify-center rounded-l-[6px] border-2 text-center text-[1vw] font-semibold">
            Patrons
          </h3>
          <h3 className="border-black flex h-10 w-3/5 items-center justify-center rounded-r-[6px] border-2 border-l-0 text-center text-[1vw] font-semibold">
            Last Friday Sale
          </h3>
        </div>
        {patronList
          .filter((patron) => !step1GroupIds.includes(patron.id))
          .map((patron) => (
            <div className="">
              <div
                key={patron.id}
                className="flex flex-row rounded-lg border border-[#DDE8FF] text-[1vw]"
              >
                <div className="flex h-[20px] w-2/5 items-center justify-center rounded-l-[6px] bg-[#EAEEFF85] ">
                  {patron.name}
                </div>
                <div className="flex h-[20px] w-3/5 items-center justify-center rounded-r-[6px] bg-color2 ">
                  {patron.actualDaily}
                </div>
              </div>
            </div>
          ))}
      </ul>
      {Display ? (
        <button
          className="button-class absolute bottom-0 mb-2 flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
          onClick={(e) => {
            e.preventDefault();
            incrementCurrentStep();
          }}
        >
          Continue
        </button>
      ) : null}
      {Display ? (
        <div className="absolute bottom-1 right-1 flex w-[22%] animate-animateFadeIn flex-col rounded-lg bg-[#0C399F] text-white shadow-lg">
          <div className="border-slate-400 w-full rounded-t-lg border-b p-2 font-semibold">
            Keys
          </div>
          <div className="flex w-full flex-col bg-color1">
            <div className="border-slate-400 flex w-full items-center justify-between border-b bg-color1 p-2 text-sm">
              <span>Segment 1:</span>
              <span>
                &le;{" "}
                {
                  patronList.find(
                    (patron) =>
                      patron.id === step1GroupIds[step1GroupIds.length - 1]
                  )?.actualDaily
                }{" "}
                Pints
              </span>
            </div>
            <div className="flex w-full items-center justify-between bg-color2 p-2 text-sm">
              <span>Segment 2:</span>
              <span>
                &ge;{" "}
                {
                  patronList[
                    patronList.findIndex(
                      (patron) =>
                        patron.id === step1GroupIds[step1GroupIds.length - 1]
                    ) + 1
                  ]?.actualDaily
                }{" "}
                Pints
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
