import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Profile from "components/profiler/profiler";
import { ColumnDef } from "@tanstack/react-table";

const ViewUser = () => {
  const userDetails = {
    Name: "Mark Musk",
    Username: "Markelon",
    User_Role: "Admin",
    Email: "qwerty@gmail.com",
    Status: "ACTIVE",
    Created_Date: "09-01-2022",
    Client: "ROAI",
  };

  return (
    <Box
      id="addtenant-form"
      overflowX="auto"
      // bg={"Containerbg"}
      borderRadius={"20px"}
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "18vw" }}
    >
      <Flex direction={"column"} flex={1}>
        <Flex
          bg={"TableHeaderbg"}
          justify="space-between"
          align={"center"}
          py={"10px"}
          px={"20px"}
          textColor="#0B389E"
          fontWeight={600}
          fontSize={"16px"}
        >
          View User
        </Flex>
        <Flex
          bg={"Containerbg"}
          flex={1}
          py={"10px"}
          px={"20px"}
          direction={"column"}
          justifyContent="space-between"
        >
          <Profile
            Details={userDetails}
            link={"/user/edit/"}
            marginspace={10}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default ViewUser;
