import type { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState, useEffect } from "react";
import "./leaderboard.css";
import axiosClient from "../../../config/apiClient";
import { roundOff } from "config/utils";
import { useLocation } from "react-router-dom";
import { useGameStore } from "../../../store/store";
import { toast } from "react-toastify";
import HistoricalDataComponent from "views/instructor/HistoricalData/historicaldatanew";
import { checkSubResourcePermission } from "helper/checkResorcePermission";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { RiMore2Fill, RiHistoryLine } from "react-icons/ri";

import { Table } from "./table";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */
interface LeaderboardData {
  data_dict: {
    total: string;
    team_name: string;
    team_id: number;
    RevenuePeriod1: string;
    RevenuePeriod2: string;
    RevenuePeriod3: string;
    PricePeriod1: string;
    PricePeriod2: string;
    PricePeriod3: string;
    inventory_Period1: string;
    inventory_Period2: string;
    inventory_Period3: string;
    inventory_balance: string;
  }[];
  teamname: string;
  num_teams: number;
}

interface SelectOptions {
  tenant_id: string;
  workshop_id: string;
}

const Leaderboard = () => {
  let { authData, Workshop, PermissionsList } = useGameStore();
  let allColumns;
  const [Loading, setLoading] = useState(false);
  const [fetching, setfetching] = useState(false);
  const [viewhistory, setviewhistory] = useState(false);
  const [isBlurActive, setIsBlurActive] = useState(false);
  const location = useLocation();
  const isPlayable = location.pathname.includes("play");
  interface HistoricalData {
    id: number;
    user_id: number;
    username: string;
    team_id: number;
    teamname: string;
    pe_id: number;
    price: number;
    period: string;
    sale: number;
    revenue: number;
    cumulative_revenue: number;
    beginning_inventory: number;
    ending_inventory: number;
    date: string;
    created_at: string;
  }
  const [historydata, setHistoryData] = useState<HistoricalData[]>([]);
  const [data, setData] = useState<LeaderboardData>({
    data_dict: [],
    teamname: "",
    num_teams: 0,
  });
  const [tenantOptions, setTenantOptions] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [workshopOptions, setworkshopOptions] = useState([
    {
      label: "",
      value: "",
    },
  ]);
  const [selectOptions, setSelectOptions] = useState<SelectOptions>({
    tenant_id: "",
    workshop_id: "",
  });

  const getleaderboard = async () => {
    try {
      setLoading(true);
      let response;
      if (isPlayable) {
        response = await axiosClient.get(
          `/workshop/leaderboard/?acronym=${Workshop.acronym}&workshop=${Workshop.workshop_id}`
        );
      } else {
        if (
          PermissionsList &&
          checkSubResourcePermission(
            PermissionsList,
            "leaderboard",
            "DELETE"
          ) &&
          checkSubResourcePermission(PermissionsList, "client", "LIST")
        ) {
        } else {
          response = await axiosClient.get(`/workshop/leaderboard/`);
        }
        // if(checkSubResourcePermission(PermissionsList, "leaderboard", "DELETE")){
        //   response = await axiosClient.get(
        //     `/workshop/leaderboard/overall/?tenant_id=${37}&workshop_id=${62}`
        //   );
        // }else{
        // }
      }
      setLoading(false);
      let updatedData = response.data;
      updatedData.data_dict = response?.data?.data_dict.map((obj) => {
        let totalRevenue = 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod1)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod2)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod3)) || 0;
        obj.total = totalRevenue;
        return obj;
      });
      setData(updatedData);
    } catch (error) {
      console.error("Error sending data to the server:", error);
      setLoading(false);
      return;
    }
  };

  const getSelectOptions = async () => {
    try {
      const [clientListingData] = await Promise.all([
        (await axiosClient.get("/tenant/get/tenants/")).data,
      ]);
      // setTableData(clientListingData);
      setTenantOptions(
        clientListingData.map((element) => ({
          label: element.name,
          value: element.id,
        }))
      );
      setSelectOptions((prev) => ({
        ...prev,
        tenant_id: "",
        workshop_id: "",
      }));
    } catch (error) {
      console.error("Error fetching configuration data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAdminLeaderBoard = async (tenantId, workshopId = null) => {
    try {
      setLoading(true);
      const response = await axiosClient.get(
        `/workshop/leaderboard/overall/?tenant_id=${tenantId.value}${
          workshopId ? `&workshop_id=${workshopId.value}` : ""
        }`
      );
      const LeaderboardData = response.data;
      let updatedData = LeaderboardData;
      updatedData.data_dict = response?.data?.data_dict.map((obj) => {
        let totalRevenue = 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod1)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod2)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod3)) || 0;
        obj.total = totalRevenue;
        return obj;
      });
      setData(updatedData);
    } catch (error) {
      console.error("Error fetching workshop data:", error);
      setData((prev) => ({
        ...prev,
        data_dict: [],
        teamname: "",
        num_teams: 0,
      }));
    } finally {
      setLoading(false);
    }
  };

  const getWorkshopOptions = async (tenantId) => {
    try {
      setfetching(true);
      const response = await axiosClient.get(
        `/workshop/get/?tenant_id=${tenantId.value}`
      );
      const workshopListingData = response.data;
      setSelectOptions((prev) => ({
        ...prev,
        ["workshop_id"]: "",
      }));
      setworkshopOptions(
        workshopListingData.map((element) => ({
          label: element.name,
          value: element.id,
        }))
      );
    } catch (error) {
      console.error("Error fetching workshop data:", error);
    } finally {
      setfetching(false);
    }
  };

  useEffect(() => {
    if (selectOptions.tenant_id) {
      getWorkshopOptions(selectOptions.tenant_id);
    }
    if (selectOptions.tenant_id) {
      getAdminLeaderBoard(selectOptions.tenant_id);
    }
  }, [selectOptions.tenant_id]);

  useEffect(() => {
    if (selectOptions.workshop_id && selectOptions.tenant_id) {
      getAdminLeaderBoard(selectOptions.tenant_id, selectOptions.workshop_id);
    }
  }, [selectOptions.workshop_id]);

  useEffect(() => {
    if (
      PermissionsList &&
      checkSubResourcePermission(PermissionsList, "leaderboard", "DELETE") &&
      checkSubResourcePermission(PermissionsList, "client", "LIST") &&
      !isPlayable
    ) {
      getSelectOptions();
    }
    getleaderboard();
  }, []);

  const addPrefixAndRoundOff = (value: any) => {
    if (value === 0 || value === null) return;
    return `$${roundOff(value)}`;
  };

  const cols = useMemo<ColumnDef<LeaderboardData["data_dict"][0]>[]>(
    () => [
      {
        header: "Team Name",
        cell: (row) => row.renderValue(),
        accessorKey: "team_name",
      },
      {
        header: "Period 1 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod1",
      },
      {
        header: "Period 2 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod2",
      },
      {
        header: "Period 3 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod3",
      },
      {
        header: "Total Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "total",
      },
      // {
      //   header: "Insights",
      //   // cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
      //   accessorKey: "insights",
      // },
    ],
    []
  );
  if (
    PermissionsList &&
    checkSubResourcePermission(PermissionsList, "leaderboard", "DELETE") &&
    !isPlayable
  ) {
    const buttonColumns: ColumnDef<LeaderboardData["data_dict"][0]>[] = [
      {
        id: "actions",
        cell: (info) => (
          <Menu autoSelect={false}>
            <MenuButton
              as={IconButton}
              icon={<RiMore2Fill />}
              variant="ghost"
              _hover={{}}
              size="md"
            />
            <MenuList w={150} minW={0}>
              {/* {onViewItem && (
                <MenuItem
                  _hover={{ bg: "HeaderFillbg", color: "white" }}
                  onClick={() => onViewItem(info.row.original)}
                >
                  <RiEyeLine className="mr-2 h-6 w-6" /> View
                </MenuItem>
              )} */}
              <MenuItem
                _hover={{ bg: "HeaderFillbg", color: "white" }}
                onClick={() => getHistoricalData(info.row.original)}
                w={148}
              >
                <RiHistoryLine className="mr-2 h-5 w-5" />
                Historical
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ];

    allColumns = [...buttonColumns, ...cols];
  } else {
    allColumns = [...cols];
  }
  const getReset = async (rowData) => {
    try {
      setLoading(true);
      const response = await axiosClient.post(`game3/getreset`, {
        team_ids: rowData,
      });

      setLoading(false);
      toast.success(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Reset Successful
          </div>
          <div className="font-Helvetica text-sm tracking-wide">
            Team reset Successful
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
      getleaderboard();
      return response.data;
    } catch (error) {
      setLoading(false);
      if (rowData.team_name !== "") {
        setIsBlurActive(true);
        toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Data Not Found
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Team Reset unsuccessful
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
      }
      console.error("Error sending data to the server:", error);
    }
  };
  const getHistoricalData = async (
    rowData: LeaderboardData["data_dict"][0]
  ) => {
    try {
      setLoading(true);
      const isAdmin = PermissionsList && PermissionsList.name === "Admin";
      const response = await axiosClient.post(`workshop/historical/`,{ team_id: rowData.team_id });
      setLoading(false);
      setHistoryData(response.data);
      setviewhistory(true);
      return response.data;
    } catch (error) {
      setLoading(false);
      if (rowData.team_name !== "") {
        setIsBlurActive(true);
        toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Data Not Found
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Historical data for "{rowData.team_name}" does not exist
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
      }
      console.error("Error sending data to the server:", error);
    }
  };
  const handleBackClick = () => {
    setviewhistory(false);
    setHistoryData([]);
  };
  const handleResetAll = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.delete(`game3/resetAllTeam`);
      setLoading(false);
      toast.success(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Reset Successful
          </div>
          <div className="font-Helvetica text-sm tracking-wide">
            Reset Successful for all teams
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
      getleaderboard();
      return response.data;
    } catch (error) {
      setLoading(false);
      setIsBlurActive(true);
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Error
          </div>
          <div className="font-Helvetica text-sm tracking-wide">
            Reset unsuccessful
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
      console.error("Error sending data to the server:", error);
    }
  };
  const handleGameChange = (selectedOption, fieldId) => {
    setSelectOptions((prev) => ({
      ...prev,
      [fieldId]: selectedOption,
    }));
  };
  return (
    <>
      <div className="mt-20 lg-max:hidden">
        {Loading ? (
          <div className="flex items-center justify-center">
            <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-blue-600"></div>
          </div>
        ) : !viewhistory ? (
          <Table
            data={data.data_dict}
            tenantOption={tenantOptions}
            columns={allColumns}
            teamname={data.teamname}
            numberofTeams={data.num_teams}
            BlurActive={isBlurActive}
            selectOptions={selectOptions}
            handleResetAll={handleResetAll}
            handleGameChange={handleGameChange}
            setselectOptions={setSelectOptions}
            workshopOptions={workshopOptions}
            isLoading={Loading}
            isFetching={fetching}
            getAdminLeaderBoard={getAdminLeaderBoard}
            getReset={getReset}
          />
        ) : viewhistory ? (
          <>
            <HistoricalDataComponent
              historicaldata={historydata}
              handleBackClick={handleBackClick}
            ></HistoricalDataComponent>
          </>
        ) : null}
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
};
export default Leaderboard;
