import React, { useEffect, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import GenericDataTable from "../../../../components/table/ListingTable";
import { useNavigate } from "react-router-dom";
import axiosClient from "config/apiClient";
import Loader from "components/loader/loader";
import { sortByModifiedDate } from "helper/sortList";
import { Text, useDisclosure } from "@chakra-ui/react";

interface DataType {
  id: string;
  name: string;
  game: string;
  filetype: string;
  uploaded_file_name: string;
  image_binary_str: string;
}

export const ConfigurationListing = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedPDFBlob, setSelectedPDFBlob] = useState<Blob | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const handleCreateItem = () => {
    navigate("/configuration/create");
  };

  const handleViewItem = (item: DataType) => {
    navigate(`/configuration/view/${item.id}`, {
      state: { type: item.filetype },
    });
  };

  const handleEditItem = (item: DataType) => {
    navigate(`/configuration/edit/${item.id}`, {
      state: { type: item.filetype },
    });
  };

  const columns: ColumnDef<DataType>[] = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Game",
      accessorKey: "game",
    },
    {
      header: "File Type",
      accessorKey: "filetype",
    },
    {
      header: "File Name",
      accessorKey: "uploaded_file_name",
    },
  ];

  const getConfigurationListing = async () => {
    try {
      const [patronResponse, elasticityResponse] = await Promise.all([
        axiosClient.get("flow/metrics/get/patrons/"),
        axiosClient.get("flow/metrics/get/elasticity/"),
      ]);

      const patronData = patronResponse.data.map((item) => ({
        ...item,
        filetype: "Time to Patron",
        game: "Game 3",
      }));

      const elasticityData = elasticityResponse.data.map((item) => ({
        ...item,
        filetype: "Price Elasticity",
        game: "Game 3",
      }));

      const tableData = sortByModifiedDate([...patronData, ...elasticityData]);
      setTableData(tableData);
    } catch (error) {
      console.error("Error fetching configuration data:", error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    setIsInitialLoading(true);
    getConfigurationListing();
  }, []);

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <>
          <GenericDataTable
            data={tableData}
            columns={columns}
            onCreateItem={handleCreateItem}
            onViewItem={handleViewItem}
            onEditItem={handleEditItem}
            createButtonText="Create Configuration"
            isViewable={false}
            similarWidth={false}
            actionsPresent={true}
            showTopBar={true}
            onClose={onClose}
            onOpen={onOpen}
            selectedPDFBlob={selectedPDFBlob}
            setSelectedPDFBlob={setSelectedPDFBlob}
            isOpen={isOpen}
          />
        </>
      )}
    </>
  );
};
