import React from "react";
import {
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from "@chakra-ui/react";
import GenericDataTable from "../table/ListingTable";
import Profile from "../profiler/profiler";

interface TabbedContentComponentProps {
  tabConfigurations: any[];
  userDetails?: Record<string, any>;
  isViewable?: boolean;
  dataCount?: number;
  similarWidth?: boolean;
  actionsPresent?: boolean;
  createButtonText?: string;
  showTopBar?: boolean;
  link?: string;
  header?: string;
  SecondarycreateButtonText?: string;
  SecondaryButtonDisable?: boolean;
  onCreateItem?: () => void;
  onSecondaryAction?: () => void;
  onViewItem?: (item: any) => void;
  onEditItem?: (item: any) => void;
  onCloseItem?: (item: any) => void;
  setSelectedPDFBlob?: (blob: Blob) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
  selectedPDFBlob?: Blob;
}

const TabbedContentComponent = ({
  tabConfigurations,
  userDetails,
  isViewable = true,
  dataCount = 5,
  similarWidth = true,
  actionsPresent = false,
  showTopBar = false,
  onCreateItem,
  onEditItem,
  onViewItem,
  onCloseItem,
  onSecondaryAction,
  SecondarycreateButtonText,
  SecondaryButtonDisable,
  createButtonText,
  link,
  header,
  selectedPDFBlob,
  setSelectedPDFBlob,
  isOpen,
  onClose,
  onOpen,
}: TabbedContentComponentProps) => {
  return (
    <Flex direction="column" flex={1}>
      <Tabs
        isFitted
        variant="unstyled"
        display="flex"
        flexDirection="column"
        flex={1}
      >
        <TabList
          width="100%"
          bg="TableHeaderbg"
          textColor="#0B389E"
          fontWeight={600}
          fontSize="18px"
        >
          {tabConfigurations.map((tab, index) => (
            <Tab
              key={index}
              fontSize="18px"
              _selected={{ color: "white", borderBottomWidth: "2px" }}
            >
              {tab.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels
          bg="Containerbg"
          flex={1}
          display="flex"
          flexDirection="column"
        >
          {tabConfigurations.map((tab, index) => (
            <TabPanel
              key={index}
              flex={1}
              display="flex"
              flexDirection="column"
            >
              <Flex flex={1} flexDirection="column">
                {tab.type === "table" ? (
                  <GenericDataTable
                    data={tab.data}
                    columns={tab.columns}
                    createButtonText={
                      createButtonText
                        ? createButtonText
                        : `Create New ${tab.name}`
                    }
                    isViewable={isViewable}
                    actionsPresent={actionsPresent}
                    dataCount={dataCount}
                    similarWidth={similarWidth}
                    showTopBar={showTopBar}
                    onCreateItem={onCreateItem}
                    onViewItem={onViewItem}
                    onEditItem={onEditItem}
                    onCloseItem={onCloseItem}
                    onSecondaryAction={onSecondaryAction}
                    SecondarycreateButtonText={SecondarycreateButtonText}
                    SecondaryButtonDisable={SecondaryButtonDisable}
                  />
                ) : tab.type === "component" ? (
                  <>
                    {userDetails && (
                      <Profile
                        Details={userDetails}
                        link={link}
                        header={header}
                      />
                    )}
                    <Flex flex={1} flexDir="column" id="flex">
                      <GenericDataTable
                        data={tab.data}
                        columns={tab.columns}
                        createButtonText={
                          createButtonText
                            ? createButtonText
                            : `Create New ${tab.name}`
                        }
                        isViewable={isViewable}
                        dataCount={dataCount}
                        similarWidth={similarWidth}
                        actionsPresent={actionsPresent}
                        showTopBar={showTopBar}
                        onCreateItem={onCreateItem}
                        onViewItem={onViewItem}
                        onEditItem={onEditItem}
                        onCloseItem={onCloseItem}
                        onSecondaryAction={onSecondaryAction}
                        SecondarycreateButtonText={SecondarycreateButtonText}
                        SecondaryButtonDisable={SecondaryButtonDisable}
                        onClose={onClose}
                        onOpen={onOpen}
                        selectedPDFBlob={selectedPDFBlob}
                        setSelectedPDFBlob={setSelectedPDFBlob}
                        isOpen={isOpen}
                      />
                    </Flex>
                  </>
                ) : (
                  <Text>Unsupported tab type</Text>
                )}
              </Flex>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default TabbedContentComponent;
