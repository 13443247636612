export function useAuthData() {
  const gameStoreData = localStorage.getItem("game-store");
  let authData;
  if (gameStoreData) {
    try {
      const gameStoreObject = JSON.parse(gameStoreData);
      if (
        gameStoreObject &&
        gameStoreObject.state &&
        gameStoreObject.state.authData
      ) {
        authData = gameStoreObject.state.authData;
      }
    } catch (error) {
      console.log("Error parsing the local storage data:", error);
    }
  } else {
    console.log("No 'game-store' data found in local storage.");
  }
  return authData;
}

export function useAuth() {
  return useAuthData();
}
