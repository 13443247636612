import { ColumnDef } from "@tanstack/react-table";
import GenericDataTable from "../../../../components/table/ListingTable";
import { useNavigate } from "react-router-dom";

interface DataType {
  id: string;
  name: string;
  fullname: string;
  userrole: string;
  email: string;
  client: string;
  date: string;
  status: string;
}

const columns: ColumnDef<DataType>[] = [
  {
    header: "User Name",
    accessorKey: "name",
  },
  {
    header: "Full Name",
    accessorKey: "fullname",
  },
  {
    header: "User Role",
    accessorKey: "userrole",
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Client",
    accessorKey: "client",
  },
  {
    header: "Date",
    accessorKey: "date",
  },
  {
    header: "Status",
    accessorKey: "status",
  },
  // ... other columns
];

export const UserListing = () => {
  const data: DataType[] = [
    {
      id: "id_0001",
      name: "John",
      fullname: "John Doe",
      userrole: "player",
      email: "Johndoe@gmail.com",
      client: "ROAI",
      date: "03-03-2024",
      status: "Active",
    },
  ];

  const navigate = useNavigate();

  const handleCreateItem = () => {
    navigate("/user/create");
  };

  const handleViewItem = (item: DataType) => {
    navigate(`/user/view/${item.id}`);
  };

  const handleEditItem = (item: DataType) => {
    navigate(`/user/edit/${item.id}`);
  };

  return (
    <GenericDataTable
      data={data}
      columns={columns}
      onCreateItem={handleCreateItem}
      onViewItem={handleViewItem}
      onEditItem={handleEditItem}
      createButtonText="Create User"
      isViewable={false}
      similarWidth={false}
      actionsPresent={true}
      showTopBar={true}
    />
  );
};
