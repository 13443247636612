import { Routes, Route, Navigate } from "react-router-dom";

// import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import InstructorLayout from "layouts/InstructorLayout";

import Pusher from "pusher-js";
import { PusherProvider } from "./context/pusher/PusherContext";
import SystemAdminLayout from "layouts/systemadmin";
import MainLayout from "layouts/main/mainLayout";
import WorkshopLayout from "layouts/workshop/workshopLayout";

const App = () => {
  const pusherId = process.env.REACT_APP_PUSHER_ID
  const cluster = process.env.REACT_APP_PUSHER_CLUSTER

  const pusher = new Pusher(pusherId, {
    cluster: cluster,
    forceTLS: true,
    activityTimeout : 150000
  });

  return (
    <PusherProvider pusher={pusher}>
      <Routes>
        <Route path="/play/*" element={<WorkshopLayout />} />
        <Route path="auth/*" element={<AuthLayout />} />
        {/* <Route path="instructor/*" element={<InstructorLayout />} /> */}
        {/* <Route path="game/*" element={<AdminLayout />} /> */}
        {/* <Route path="systemadmin/*" element={<SystemAdminLayout />} /> */}
        <Route path="/*" element={<MainLayout />} />
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </PusherProvider>
  );
};

export default App;
