import About from "views/about";
import Leaderboard from "views/game3/leaderboard/leaderboard";
import { Game3 } from "views/game3";
import Game1A from "views/game1/game1A/game1A";
import Play from "views/game2";
import Game1B from "views/game1/game1B";

const workshoproutes = [
  {
    name: "About",
    layout: "/play",
    path: "about",
    component: <About />,
    hidden: false,
    protected: true,
  },
  {
    name: "Leaderboard",
    layout: "/play",
    path: "leaderboard",
    component: <Leaderboard />,
    hidden: false,
    protected: true,
  },
  {
    name: "Game1",
    layout: "/play",
    path: "game1A",
    component: <Game1A />,
    hidden: false,
    protected: true,
  },
  {
    name: "Game2",
    layout: "/play",
    path: "game2",
    component: <Play />,
    hidden: false,
    protected: true,
  },
  {
    name: "game1B",
    layout: "/play",
    path: "game1B",
    component: <Game1B />,
    hidden: true,
    protected: true,
  },
  {
    name: "Game3",
    layout: "/play",
    path: "game3",
    component: <Game3 />,
    hidden: false,
    protected: true,
  },
];

export default workshoproutes;
