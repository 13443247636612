import React from "react";
import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { useGameStore } from "../../../store/store";
import { toast } from "react-toastify";

export default function Game1AHeader() {
  const currentStep = useGameStore((state) => state.currentStep);
  const resetGame = useGameStore((state) => state.Game1reset);

  const handleResetGame = () => {
    resetGame();
    toast.success(
      <>
        <div className="font-Helvetica text-lg font-bold tracking-wide">
          Success
        </div>
        <div className="font-Helvetica text-sm tracking-wide">
          Game Reset successfull!
        </div>
      </>,
      {
        position: "bottom-center",
        autoClose: 5000,
        type: "success",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  };

  return (
    <Flex
      w="full"
      alignItems="center"
      justifyContent="right"
      px={{ base: 4, md: 10 }}
      position={"absolute"}
      zIndex={2}
    >
      <Box>
        <Button
          onClick={handleResetGame}
          size="md"
          rounded="md"
          px="5"
          py="1"
          fontWeight="bold"
          border="1px"
          borderColor="#1d47b0"
          bgGradient="linear(to-b, #279df3 0%, #0466ad 100%)"
          color="white"
          position="relative"
          _before={{
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            boxShadow: "inset 3px 4px 4px 0px #3072d6",
            borderRadius: "5px",
          }}
          _hover={{
            transform: "translateY(-4px)",
          }}
          transition="all 0.3s ease-in-out"
        >
          Reset Game
        </Button>
      </Box>

      {/* <Flex gap={2}>
        {[0, 1, 2, 3, 4].map((step) => (
          <Box
            key={step}
            h="3"
            w={{ base: "30px", sm: "60px", md: "80px", lg: "100px", xl: "140px" }}
            borderRadius="xl"
            bg={currentStep > step ? "blue.500" : "gray.200"}
          />
        ))}
      </Flex> */}
    </Flex>
  );
}
