export const filterRoutesByPermissions = (routes, permissions) => {
    const allowedResources = permissions.resourcesMap.map((resource) =>
      resource.resourceName.toLowerCase()
    );
    return routes
      .map((route) => {
        const routePath = route.path.toLowerCase();
        const isAllowed =
          allowedResources.includes(routePath) ||
          routePath === "about";
        return isAllowed ? route : null;
      })
      .filter(Boolean);
  };

  export const filterRoutesExcludingPermissions = (routes, permissions) => {
    const excludedResources = permissions.resourcesMap.map((resource) =>
      resource.resourceName.toLowerCase()
    );
  
    return routes
      .map((route) => {
        const routePath = route.path.toLowerCase();
        const isExcluded = 
          excludedResources.includes(routePath) || 
          routePath === "about";
        
        return !isExcluded ? route : null;
      })
      .filter(Boolean);
  };