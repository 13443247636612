import UnAuthenticatedPage from "components/pages/UnAuthenticatedPage";
import { useLocation } from "react-router";
import { useGameStore } from "store/store";

export const ProtectedRoute = ({ children }) => {
  const { Workshop, authData } = useGameStore();
  const location = useLocation();
  const isAuthenticated = () => {
    const isPlayable = location.pathname.includes("play");

    return isPlayable ? Workshop !== null : authData !== null;
  };

  return isAuthenticated() ? children : <UnAuthenticatedPage />;
};
