import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const PDFModal = ({ isOpen, onClose, pdfBlob }) => {
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      setPdfUrl(url);
      return () => URL.revokeObjectURL(url);
    }
  }, [pdfBlob]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxW="900px" h="90vh">
        <ModalHeader>PDF Viewer</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              width="100%"
              height="100%"
              style={{ border: "none" }}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PDFModal;
