import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
  FilterFn,
  SortingState,
} from "@tanstack/react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  Text,
  Box,
  VStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
  Center,
  Select,
} from "@chakra-ui/react";
import {
  RiEyeLine,
  RiEditBoxLine,
  RiSearch2Line,
  RiMore2Fill,
  RiArrowUpSLine,
  RiArrowDownSLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { handleOpenPDF } from "helper/pdfModalOpener";
import PDFModal from "views/systemadmin/configuration/Components/PdfModal";

// Helper function to get timestamp for sorting
const getDateValue = (dateString: string): number => {
  try {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 0 : date.getTime();
  } catch {
    return 0;
  }
};

// Helper function to check if a string contains date-related terms
const isDateColumn = (header: string): boolean => {
  const dateTerms = ['date', 'created', 'updated', 'time'];
  return dateTerms.some(term => header.toLowerCase().includes(term));
};

// Custom date sorting function
const sortDateTime = (rowA: any, rowB: any, columnId: string): number => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);
  
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;

  const timestampA = getDateValue(a);
  const timestampB = getDateValue(b);

  if (timestampA === 0 && timestampB === 0) {
    return a < b ? -1 : a > b ? 1 : 0;
  }

  return timestampA - timestampB;
};

interface GenericDataTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  onCreateItem?: () => void;
  onSecondaryAction?: () => void;
  onViewItem?: (item: T) => void;
  onEditItem?: (item: T) => void;
  onCloseItem?: (item: T) => void;
  createButtonText?: string;
  SecondarycreateButtonText?: string;
  SecondaryButtonDisable?: boolean;
  isViewable?: boolean;
  dataCount?: number;
  similarWidth?: boolean;
  actionsPresent?: boolean;
  showTopBar?: boolean;
  setSelectedPDFBlob?: (blob: Blob) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
  selectedPDFBlob?: Blob;
}

function GenericDataTable<T extends object>({
  data,
  columns,
  onCreateItem,
  onViewItem,
  onEditItem,
  onCloseItem,
  onSecondaryAction,
  setSelectedPDFBlob,
  onOpen,
  isOpen,
  onClose,
  createButtonText = "Create Item",
  SecondarycreateButtonText,
  SecondaryButtonDisable,
  isViewable = false,
  dataCount = 10,
  similarWidth = true,
  actionsPresent = false,
  showTopBar = false,
  selectedPDFBlob,
}: GenericDataTableProps<T>) {
  const [globalFilter, setGlobalFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(dataCount);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const memoizedColumns = useMemo<ColumnDef<T>[]>(
    () => [
      {
        id: "actions",
        cell: (info) => (
          <Menu autoSelect={false}>
            {actionsPresent && (
              <MenuButton
                as={IconButton}
                icon={<RiMore2Fill />}
                variant="ghost"
                _hover={{}}
                size="md"
              />
            )}
            <MenuList w={120} minW={0}>
              {onViewItem && (
                <MenuItem
                  _hover={{ bg: "HeaderFillbg", color: "white" }}
                  onClick={() => onViewItem(info.row.original)}
                  w={118}
                >
                  <RiEyeLine className="mr-2 h-5 w-5" /> View
                </MenuItem>
              )}
              {onEditItem && (
                <MenuItem
                  _hover={{ bg: "HeaderFillbg", color: "white" }}
                  onClick={() => onEditItem(info.row.original)}
                  w={118}
                >
                  <RiEditBoxLine className="mr-2 h-5 w-5" /> Edit
                </MenuItem>
              )}
              {onCloseItem && info.row.original["isEndable"] && (
                <MenuItem
                  _hover={{ bg: "HeaderFillbg", color: "white" }}
                  onClick={() => onCloseItem(info.row.original)}
                  w={118}
                >
                  <RiCloseCircleLine className="mr-2 h-5 w-5" />
                  End
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        ),
        width: "1px",
      },
      ...columns.map((column) => {
        const headerString = String(column.header);
        
        if (column.header === "File Name") {
          return {
            ...column,
            cell: ({ row }) => (
              <Text
                cursor="pointer"
                color="HeaderFillbg"
                _hover={{ textDecoration: "underline" }}
                onClick={() =>
                  handleOpenPDF(
                    row.original.image_binary_str,
                    setSelectedPDFBlob,
                    onOpen
                  )
                }
              >
                {row.original.uploaded_file_name}
              </Text>
            ),
          };
        }

        if (isDateColumn(headerString)) {
          return {
            ...column,
            enableSorting: true,
            enableColumnFilter: true,
            sortingFn: (rowA, rowB, columnId) => sortDateTime(rowA, rowB, columnId),
            cell: (info) => info.getValue() as string,
          };
        }

        return {
          ...column,
          enableSorting: true,
          enableColumnFilter: true,
        };
      }),
    ],
    [columns, onEditItem, actionsPresent]
  );

  const table = useReactTable({
    data,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter,
      pagination: {
        pageIndex,
        pageSize: rowsPerPage,
      },
      sorting,
      columnFilters,
    },
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({ pageIndex, pageSize: rowsPerPage });
        setPageIndex(newState.pageIndex);
      } else {
        setPageIndex(updater.pageIndex);
      }
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    pageCount: Math.ceil(data.length / rowsPerPage),
    globalFilterFn: "auto",
  });

  // useEffect(() => {
  //   const updateRowsPerPage = () => {
  //     if (tableContainerRef.current) {
  //       const containerHeight = tableContainerRef.current.clientHeight;
  //       const headerHeight = 60;
  //       const rowHeight = 50;
  //       const footerHeight = 46;
  //       const availableHeight = containerHeight - headerHeight - footerHeight;
  //       const calculatedRows = Math.floor(availableHeight / rowHeight);
  //       setRowsPerPage(Math.max(calculatedRows, 1));
  //     }
  //   };

  //   updateRowsPerPage();
  //   window.addEventListener("resize", updateRowsPerPage);

  //   return () => window.removeEventListener("resize", updateRowsPerPage);
  // }, []);

  useEffect(() => {
    table.setPageSize(rowsPerPage);
  }, [rowsPerPage, table]);

  return (
    <VStack align="stretch" spacing={4} h="full">
      <Box
        mx={{ base: "0vw", md: "18vw" }}
        borderRadius="20px"
        display="flex"
        flexDirection="column"
        flex={1}
        {...(isViewable
          ? { mx: "0vw", bg: "transparent" }
          : { mx: { base: "0vw", md: "18vw" }, bg: "Containerbg" })}
      >
        {showTopBar && (
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            alignItems={{ base: "stretch", md: "center" }}
            pb={2}
            pt={4}
            pr={8}
            gap={4}
            {...(isViewable
              ? { pl: { base: 4, md: "70px" } }
              : { pl: { base: 4, md: "70px" } })}
          >
            <InputGroup width={{ base: "100%", md: "50%" }}>
              <InputLeftElement pointerEvents="none">
                <RiSearch2Line color="#000000" />
              </InputLeftElement>
              <Input
                placeholder="Search..."
                value={globalFilter ?? ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                bgColor="#FFFFFF"
                borderRadius="8px"
              />
            </InputGroup>
            {onSecondaryAction && (
              <Button
                bgGradient="linear(to-b, #5C86DC,#2647B0)"
                onClick={onSecondaryAction}
                width={{ base: "100%", md: "auto" }}
                textColor="white"
                fontWeight={700}
                fontFamily="Poppins"
                px={4}
                py={2}
                borderRadius="8px"
                _hover={{}}
                whiteSpace="normal"
                height="auto"
                textAlign="center"
                isDisabled={SecondaryButtonDisable}
              >
                {SecondarycreateButtonText}
              </Button>
            )}
            {onCreateItem && (
              <Button
                bgGradient="linear(to-b, #5C86DC,#2647B0)"
                onClick={onCreateItem}
                width={{ base: "100%", md: "auto" }}
                textColor="white"
                fontWeight={700}
                fontFamily="Poppins"
                px={4}
                py={2}
                borderRadius="8px"
                _hover={{}}
                whiteSpace="normal"
                height="auto"
                textAlign="center"
              >
                {createButtonText}
              </Button>
            )}
          </Flex>
        )}
        <TableContainer
          width="100%"
          ref={tableContainerRef}
          flex={1}
          id="contain"
          // whiteSpace={"break-spaces"}
          textOverflow={"ellipsis"}
        >
          {data.length > 0 ? (
            <Table
              variant="simple"
              size="sm"
              width="100%"
              px={actionsPresent ? 6 : 2}
              py={2}
              style={{ borderCollapse: "separate", borderSpacing: "8px" }}
            >
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id} id={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                      <Th
                        key={header.id}
                        color="white"
                        textTransform="capitalize"
                        py={3}
                        fontFamily="Poppins"
                        textColor="#0B389E"
                        fontSize={16}
                        fontWeight="600"
                        borderBottom={0}
                        bg={index === 0 ? "transparent" : "TableHeaderbg"}
                        width={
                          index === 0
                            ? "1px"
                            : !similarWidth
                            ? ""
                            : `${100 / (memoizedColumns.length - 1)}%`
                        }
                        whiteSpace={"break-spaces"}
                        hidden={!actionsPresent && index === 0}
                      >
                        {header.isPlaceholder ? null : (
                          <Flex alignItems="center">
                            <Box
                              cursor={
                                header.column.getCanSort()
                                  ? "pointer"
                                  : "default"
                              }
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Box>
                            {header.column.getCanSort() && (
                              <Box ml={1}>
                                {header.column.getIsSorted() === "asc" ? (
                                  <RiArrowUpSLine />
                                ) : header.column.getIsSorted() === "desc" ? (
                                  <RiArrowDownSLine />
                                ) : (
                                  <Box width="14px" height="14px" />
                                )}
                              </Box>
                            )}
                          </Flex>
                        )}
                        {header.column.getCanFilter() && (
                          <Input
                            value={
                              (header.column.getFilterValue() as string) ?? ""
                            }
                            onChange={(e) =>
                              header.column.setFilterValue(e.target.value)
                            }
                            placeholder={`Filter ${header.column.columnDef.header}`}
                            size="sm"
                            mt={2}
                          />
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Tr key={row.id} id={row.id}>
                    {row.getVisibleCells().map((cell, index) => (
                      <Td
                        key={cell.id}
                        id={cell.id}
                        py={2}
                        fontSize="sm"
                        borderBottom={0}
                        bg={index === 0 ? "transparent" : "TableCellbg"}
                        px={index === 0 ? "0px" : 4}
                        whiteSpace={
                          cell.column.columnDef.header
                            .toString()
                            .includes("Name")
                            ? "break-spaces"
                            : "nowrap"
                        }
                        // width={
                        //   index === 0
                        //     ? "1px"
                        //     : cell.column.columnDef.header.toString().includes("Name") ?
                        //     "50%"
                        //     : `${100 / (memoizedColumns.length - 1)}%`
                        // }
                        hidden={!actionsPresent && index === 0}
                        fontWeight={500}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Center height="100%">
              <Text fontSize="lg" color="white">
                No data available
              </Text>
            </Center>
          )}
        </TableContainer>
        {data.length > 0 && (
          <HStack justify="space-between" w="full" py={4} px={10}>
            <HStack>
              <Button
                size="sm"
                onClick={() => table.setPageIndex(0)}
                isDisabled={!table.getCanPreviousPage()}
              >
                {"<<"}
              </Button>
              <Button
                size="sm"
                onClick={() => table.previousPage()}
                isDisabled={!table.getCanPreviousPage()}
              >
                {"<"}
              </Button>
              <Button
                size="sm"
                onClick={() => table.nextPage()}
                isDisabled={!table.getCanNextPage()}
              >
                {">"}
              </Button>
              <Button
                size="sm"
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                isDisabled={!table.getCanNextPage()}
              >
                {">>"}
              </Button>
            </HStack>
            <Text fontSize="sm">
              Page{" "}
              <strong>
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </strong>
            </Text>
          </HStack>
        )}
        <PDFModal isOpen={isOpen} onClose={onClose} pdfBlob={selectedPDFBlob} />
      </Box>
    </VStack>
  );
}

export default GenericDataTable;