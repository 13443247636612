import { Box, Button, Flex, Grid, Link, Text, Image } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

interface DataType {
  Details: Record<string, any>;
  link: string;
  header?: string;
  marginspace?: number;
  linkdata?:{};
}

export default function Profile({
  Details,
  link,
  header,
  marginspace = 4,
  linkdata ={},
}: DataType) {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Box mx={3}>
      <Flex justifyContent={"flex-end"} alignItems={"center"} mb={4}>
        <Button
          bgGradient="linear(to-b, #5C86DC,#2647B0)"
          onClick={() => navigate(`${link}${params.id}`,{state : linkdata})}
          width={{ base: "100%", md: "auto" }}
          textColor={"white"}
          fontWeight={700}
          fontFamily={"Poppins"}
          px={4}
          py={2}
          borderRadius={"8px"}
        >
          Edit
        </Button>
      </Flex>
      <Grid
        templateColumns={{
          md: "repeat(2, 1fr)",
          lg: "repeat(2, 1fr)",
          base: "repeat(1, 1fr)",
        }}
        bg={"Containerbg"}
        px={8}
        pt={4}
        borderRadius={8}
      >
        {Object.keys(Details).map((key) => (
          <React.Fragment key={key}>
            {["QR_Code", "Link"].includes(key) ? (
              <Flex key={key} mb={4} wordBreak={"break-word"}>
                <Flex
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  gap={2}
                >
                  <Text fontWeight={600} mr={1}>
                    {key.replace("_", " ")}:
                  </Text>
                  {key === "QR_Code" ? (
                    <Image
                      boxSize="120px"
                      objectFit="cover"
                      src={
                        "https://quickchart.io/qr?text=Here%27s%20my%20text&dark=000&light=fff&ecLevel=Q&format=png"
                      }
                      alt="QR Code"
                      borderRadius={"8px"}
                    />
                  ) : (
                    <Link color="HeaderFillbg" href={Details[key]}>
                      {Details[key]}
                    </Link>
                  )}
                </Flex>
              </Flex>
            ) : (
              <Flex
                key={key}
                alignItems={"center"}
                justifyContent={"flex-start"}
                mb={marginspace}
                wordBreak={"break-word"}
              >
                <Text fontWeight={600} mr={1}>
                  {key.replace("_", " ")}
                </Text>
                :<Text ml={1}>{Details[key]}</Text>
              </Flex>
            )}
          </React.Fragment>
        ))}
      </Grid>
      <Flex
        justifyContent={"start"}
        alignItems={"center"}
        textColor="TextColor1"
        fontWeight={600}
        fontSize="18px"
        mt={4}
      >
        {header}
      </Flex>
    </Box>
  );
}
