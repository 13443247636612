import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type ToastType = 'success' | 'error' | 'info' | 'warning';

export const useCommonToast = () => {
  const showToast = (
    title: string,
    description: string,
    type: ToastType = 'info',
    duration: number = 3000
  ) => {
    const toastOptions: ToastOptions = {
      type,
      autoClose: duration,
      position: 'bottom-center',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    };

    toast(
      <div>
        <strong>{title}</strong>
        <div>{description}</div>
      </div>,
      toastOptions
    );
  };

  return showToast;
};

// Separate component for ToastContainer
export const ToastContainerWrapper = () => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};