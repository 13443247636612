import React from "react";
import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const UnAuthenticatedPage = () => {
  return (
    <Box
      as={Flex}
      textAlign="center"
      gap={5}
      py={10}
      px={6}
      h={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={"column"}
    >
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-b, #5C86DC,#2647B0)"
        backgroundClip="text"
      >
        401
      </Heading>
      <Heading
        display="block"
        as="h4"
        size="xl"
        bg={"black"}
        backgroundClip="text"
      >
        Please Login to view this page
      </Heading>
      <Text color={"white"} mb={6}>
        Oopsss !! You are not supposed to view this page 
      </Text>
    </Box>
  );
};

export default UnAuthenticatedPage;
