import InputField from "components/fields/InputField";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGameStore } from "store/store";
import axiosClient from "config/apiClient";
import logo from "../../assets/game1/logo.svg";
import { key } from "config/utils";
// import { getFirebaseToken } from "../../firebase";

/* eslint-disable react-hooks/exhaustive-deps */

export default function SignIn() {
  const navigate = useNavigate();
  let { setauthData, Game2reset, Game3reset, Game1reset, setPermissionsList } =
    useGameStore();
  // let fcmCount = 0;
  const [error, Seterror] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setauthData(null);
    setPermissionsList(null);
    Game1reset();
    Game2reset();
    Game3reset();
  }, []);

  const toastMessage = (message: string) => {
    toast.error(`${message ? message : ""}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  async function handleSubmit(event) {
    setLoading(true);
    event.preventDefault();
    const CryptoJS = require("crypto-js");

    let secretkey = CryptoJS.enc.Utf8.parse(key);
    const ciphertext = CryptoJS.AES.encrypt(
      event.target.password.value,
      secretkey,
      { mode: CryptoJS.mode.ECB }
    );
    const formData = {
      username: event.target.username.value.toLowerCase(),
      password: event.target.password.value,
      teamname: event.target.teamname.value.toLowerCase(),
    };
    if (!formData.username || !formData.password) {
      if (!formData.username) {
        event.target.username.classList.add("border-red-500");
        Seterror("Please enter Username");
        setLoading(false);
      } else {
        event.target.password.classList.add("border-red-500");
        Seterror("Please enter Password");
        setLoading(false);
      }
    } else {
      try {
        formData.password = ciphertext.toString();
        await axiosClient
          .post(`tenant/authenticate/login/`, formData)
          .then(async (resp) => {
            setauthData(resp.data);
            try {
              const response = await axiosClient.get(`rbac/user/permissions/`);
              setPermissionsList(response.data);
              if (resp.data) {
                navigate("/about");
              }
              // if (resp.data && resp.data.isPlayer === true) {
              //   navigate("/game/about");
              // } else {
              //   navigate("/instructor/about");
              // }
            } catch {
              toastMessage("Please Contact Admin You are not yet Authorised");
              setauthData(null);
            } finally {
              setLoading(false);
            }
          });
      } catch (error) {
        setLoading(false);
        console.error((error as any).response?.status);
        if ((error as any).response?.status === 400) {
          let errorMessage = (error as any).response?.data?.detail;
          Seterror(
            errorMessage ? errorMessage : "Username/Password is Incorrect"
          );
          toastMessage(errorMessage);
          event.target.username.classList.add("border-red-500");
          event.target.password.classList.add("border-red-500");
        } else {
          toastMessage("There was an error connecting to Server!");
        }
      }
    }
  }
  return (
    <>
      <div className="align-center flex h-screen w-[100%] flex-row items-center justify-center  lg-max:hidden">
        <div className="flex flex-col items-center bg-white p-10 text-left shadow-2xl">
          <img
            src={logo}
            className="lg:w-13 sm-max:w-15 md:w-15 relative sm:w-12 xl:w-[6rem]"
            alt="logo"
          />
          {error ? (
            <div className="relative mt-3 w-[100%] text-center font-semibold text-red-500">
              {error}
            </div>
          ) : (
            <div></div>
          )}
          <h4 className="w-[100%] px-2 py-1 text-3xl font-extrabold text-gray-500">
            {" "}
            Login{" "}
          </h4>

          <form onSubmit={handleSubmit}>
            <div className="w-80">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Username"
                placeholder="Enter username"
                id="username"
                type="text"
              />
            </div>
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password"
              placeholder="Enter password"
              id="password"
              type="password"
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Team Name"
              placeholder="Optional"
              id="teamname"
              type="text"
            />
            {loading ? (
              <div className="flex items-center justify-center gap-2 text-lg font-semibold text-[#042bad] dark:text-white">
                Authenticating
                <div className="mr-4 h-10 w-10 animate-spin rounded-full border-t-2 border-indigo-700"></div>
              </div>
            ) : (
              <button
                type="submit"
                className="linear font-xl mb-2 mt-2 w-full rounded-xl border border-[#1D47B0] bg-gradient-to-r from-[#042bad] to-[#02175c] py-[12px] text-base font-semibold text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-[brand-200]"
              >
                Log In
              </button>
            )}
          </form>
        </div>
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
}
