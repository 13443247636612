import { StateCreator } from "zustand";

export interface authData {
  refresh: String;
  access: String;
  username: String;
  teamname:string;
  team_id:string;
  type: String;
  tenant_id: Number;
  isPlayer: Boolean;
}

export interface ResourcePermissions {
  resourceName: string;
  permissionList: string[];
}

export interface PermissionsList {
  name: string;
  permissions: string[];
  resourcesMap: ResourcePermissions[];
}

export interface authSlice {
  authData: authData | null; // Initialize as null
  setauthData: (data: authData | null) => void;
  PermissionsList: PermissionsList | null;
  setPermissionsList: (data: PermissionsList | null) => void;
  isLoading: boolean | null;
  setIsLoading: (data: boolean | null) => void;
}

type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

export const createauthSlice: ImmerStateCreator<authSlice> = (set) => ({
  authData: null,
  setauthData: (data) =>
    set((state) => {
      state.authData = data;
    }),
  PermissionsList: null,
  setPermissionsList: (data) =>
    set((state) => {
      state.PermissionsList = data;
    }),
  isLoading: false,
  setIsLoading: (data) =>
    set((state) => {
      state.isLoading = data;
    }),
});
