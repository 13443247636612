import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import "../../views/game2/components/charts/CustomLineChart.css"

interface ScatterPlotProps {
  predictions: number[];
  highestPredictions: number[];
  observedValues: number[];
  rSquared: number;
}

const ScatterPlot: React.FC<ScatterPlotProps> = ({ predictions, highestPredictions , observedValues, rSquared }) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartComponentRef.current) {
      chartComponentRef.current.chart.reflow();
    }
  }, []);

  // Function to calculate linear regression
  const calculateRegression = (x: number[], y: number[]): [number, number] => {
    const n = x.length;
    let sumX = 0, sumY = 0, sumXY = 0, sumXX = 0;
    for (let i = 0; i < n; i++) {
      sumX += x[i];
      sumY += y[i];
      sumXY += x[i] * y[i];
      sumXX += x[i] * x[i];
    }
    const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;
    return [slope, intercept];
  };

  // Calculate regression line
  const [slope, intercept] = calculateRegression(observedValues, highestPredictions);

  // Get min and max values for both axes
  const minX = Math.min(...highestPredictions);
  const maxX = Math.max(...highestPredictions);
  const minY = Math.min(...highestPredictions);
  const maxY = Math.max(...highestPredictions);

  // Calculate a reasonable interval based on data range
  const range = Math.max(maxX - minX, maxY - minY);
  const interval = Math.pow(10, Math.floor(Math.log10(range))) / 2;

  const options: Highcharts.Options = {
    title: {
      text: "Prediction vs Actual Plot",
    },
    subtitle: {
      text: `Accuracy: ${rSquared.toFixed(2)}`,
    },
    xAxis: {
      title: {
        text: "Actual",
        style: {
          fontSize: "20px",
          fontWeight: "700",
        },
      },
      tickInterval: interval,
      min: Math.floor(minX / interval) * interval,
      max: Math.ceil(maxX / interval) * interval
    },
    yAxis: {
      title: {
        text: "Prediction",
        style: {
          fontSize: "20px",
          fontWeight: "700",
        },
      },
      tickInterval: interval,
      min: Math.floor(minY / interval) * interval,
      max: Math.ceil(maxY / interval) * interval
    },
    credits: {
      enabled: false,
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      borderWidth: 0,
      itemHiddenStyle: {"color": "#000000"},
    },
    series: [
      {
        type: "scatter",
        name: "Data Points",
        data: observedValues.map((obs, index) => [obs, predictions[index]]),
        color: "rgba(68, 114, 196, 1)",
        showInLegend: false,
      },
      {
        type: "line",
        name: "Regression Line",
        data: [
          [minX, slope * minX + intercept],
          [maxX, slope * maxX + intercept],
        ],
        color: "red",
        marker: {
          enabled: false,
        },
      },
    ],
    tooltip: {
      formatter: function () {
        if (this.series.name === "Data Points") {
          return `Prediction: ${this.y?.toFixed(
            2
          )}<br>Actual: ${typeof this.x === "number"?this.x?.toFixed(2):this.x}`;
        } else {
          return `Regression Line`;
        }
        // else {
        //   return `Regression Line<br>y = ${slope.toFixed(4)}x + ${intercept.toFixed(4)}<br>R² = ${rSquared.toFixed(4)}`;
        // }
      },
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </div>
  );
};

export default ScatterPlot;