export const handleOpenPDF = (
  image_binary_str: string,
  setSelectedPDFBlob: (blob: Blob) => void,
  onOpen: () => void
) => {
  const binaryString = image_binary_str.replace(/\\x/g, "");
  const bytes = new Uint8Array(
    binaryString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16))
  );
  const blob = new Blob([bytes], { type: "application/pdf" });
  setSelectedPDFBlob(blob);
  onOpen();
};
