import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Text,
  Flex,
  Divider,
  useColorModeValue,
  Box,
  Icon
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { RiUser6Line } from "react-icons/ri";

import { FaUser, FaUsers, FaSignOutAlt } from 'react-icons/fa';

const UserProfileDropdown = ({ username, teamname, onLogout }) => {
  const bgColor = useColorModeValue('white', 'gray.700');

  return (
    <Box zIndex={9999}>
        <Menu>
      <MenuButton
        as={Button}
        bg="transparent"
        p={0}
        _hover={{ bg: "" }}
        _active={{ bg: "" }}
        zIndex={1000}
        minWidth={0}
      >
        <Icon as={RiUser6Line} boxSize={6} cursor="pointer"/>
        {/* <Avatar boxSize={6} size="sm" name={username} /> */}
      </MenuButton>
      <MenuList bg={bgColor} borderColor="gray.200">
        <MenuItem closeOnSelect={false}>
          <Flex align="center">
            <Avatar size="sm" icon={<FaUser />} mr={2} />
            <Text fontWeight="medium">{username}</Text>
          </Flex>
        </MenuItem>
        {teamname && (
          <MenuItem closeOnSelect={false}>
            <Flex align="center">
              <Avatar size="sm" icon={<FaUsers />} mr={2} />
              <Text>{teamname}</Text>
            </Flex>
          </MenuItem>
        )}
        <Divider />
        <MenuItem 
          icon={<FaSignOutAlt />} 
          onClick={onLogout}
          color="red.500"
          _hover={{ bg: 'red.50' }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
    </Box>
  );
};

export default UserProfileDropdown;