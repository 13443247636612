import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  IconButton,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CloseIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  MinusIcon,
  UpDownIcon,
} from "@chakra-ui/icons";
import {
  Typewriter,
  scrollToBottom,
  fetchChatbotInstruction,
  handleSendMessage,
  renderMessage,
  RenderInitialPrompts,
  renderChatInput,
} from "../components/chatbotHelper";
import "../animation.css";
import WaveHeader from "./waveheader";
import { useGameStore } from "store/store";
import { useLocation } from "react-router-dom";

const Chatbot = ({ onClose }) => {
  const [messages, setMessages] = useState([
    { content: "Hello! How can I assist you today?", role: "assistant" },
  ]);
  const [userMessage, setUserMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showInitialPrompts, setShowInitialPrompts] = useState(true);
  const [initialPrompts, setInitialPrompts] = useState([]);
  const [maximizeWindow, setMaximizeWindow] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const messagesEndRef = useRef(null);
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { authData, Workshop } = useGameStore();
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const location = useLocation();
  const isPlayable = location.pathname.includes("play");

  useEffect(() => {
    scrollToBottom(messagesEndRef);
  }, [messages]);

  useEffect(() => {
    fetchChatbotInstruction(
      setInitialPrompts,
      setLoading,
      setMessages,
      isPlayable,
      Workshop?.acronym
    );
  }, []);

  const handlePromptClick = (prompt) => {
    setUserMessage("");
    setShowInitialPrompts(false);
    handleSendMessage(
      prompt,
      messages,
      setMessages,
      setUserMessage,
      setIsSending,
      setShowInitialPrompts,
      isPlayable,
      Workshop?.acronym,
      Workshop?.team_name
    );
  };

  const handleCloseClick = () => {
    onOpen();
  };

  const handleConfirmClose = () => {
    onModalClose();
    onClose();
  };

  const handleMinimizeClick = () => {
    if (!isMinimized) {
      setMaximizeWindow(false);
    }
    setIsMinimized(!isMinimized);
  };

  return (
    <Box
      position="fixed"
      right="4"
      bottom={isMinimized ? "4" : "auto"}
      top={"5"}
      maxW={maximizeWindow ? "70vw" : "40vw"}
      w={isMinimized ? "fit-content" : maximizeWindow ? "60vw" : "25vw"}
      minW={isMinimized ? "12vw" : "25vw"}
      transition="all 0.3s ease-in-out"
      className="z-50 lg-max:hidden"
      h={"90vh"}
      maxH={"90vh"}
    >
      <Flex
        direction="column"
        borderRadius="2xl"
        boxShadow="xl"
        overflow="hidden"
        h={isMinimized ? "auto" : "100%"}
        transition="all 0.3s ease-in-out"
        className="opacitybg"
      >
        <Flex
          bgGradient="linear(to-l, #C6C1E7,#0A379F)"
          boxShadow="2xl"
          p={4}
          color="white"
          alignItems="center"
          justifyContent="space-between"
          transition="all 0.3s ease-in-out"
        >
          <Text fontSize="xl" fontWeight="bold">
            PubPundit
          </Text>
          <HStack>
            {!isMinimized && (
              <IconButton
                icon={
                  maximizeWindow ? (
                    <ArrowRightIcon className="h-3 w-3" />
                  ) : (
                    <ArrowLeftIcon />
                  )
                }
                onClick={() => setMaximizeWindow(!maximizeWindow)}
                aria-label={maximizeWindow ? "Minimize" : "Maximize"}
                variant="ghost"
                transition="all 0.5s ease-in-out"
              />
            )}
            <IconButton
              icon={isMinimized ? <UpDownIcon /> : <MinusIcon />}
              onClick={handleMinimizeClick}
              aria-label={isMinimized ? "Maximize" : "Minimize"}
              variant="ghost"
              transition="all 0.5s ease-in-out"
            />
            {!isMinimized && (
              <IconButton
                icon={<CloseIcon />}
                onClick={handleCloseClick}
                aria-label="Close"
                variant="ghost"
                transition="all 0.5s ease-in-out"
              />
            )}
          </HStack>
        </Flex>
        <Box className={`${isMinimized ? "hidden" : "flex"}`} h={"100%"} overflow={"hidden"}>
        <Flex direction="column" overflow={"hidden"} flex={1}>
            <Box>
              <WaveHeader name={authData?.username} />
            </Box>
            <VStack
              flex={1}
              overflowY="auto"
              p={4}
              alignItems="stretch"
              spacing={4}
              transition="all 0.3s ease-in-out"
              id="chatbot-messages"
              ref={messagesEndRef}
              whiteSpace={"pre-wrap"}
              overflowWrap={"normal"}
              wordBreak={"normal"}
              className="whitespace-pre-wrap"
            >
              {messages.map((message, index) =>
                renderMessage(message, index, Typewriter, setIsSending)
              )}
            </VStack>
            <Box
              p={4}
              mx={1}
              borderTopWidth={2}
              className="[border-image:linear-gradient(90deg,#BCC3D6,#0738A0,#BCC3D6)_10]"
            >
              {loading ? (
                <Flex justify="center" align="center" direction="column">
                  <Spinner size="xl" mb={2} />
                  <Text>Fetching queries...</Text>
                </Flex>
              ) : showInitialPrompts ? (
                <RenderInitialPrompts
                  initialPrompts={initialPrompts}
                  handlePromptClick={handlePromptClick}
                />
              ) : null}

              {renderChatInput(userMessage, setUserMessage, isSending, () =>
                handleSendMessage(
                  userMessage,
                  messages,
                  setMessages,
                  setUserMessage,
                  setIsSending,
                  setShowInitialPrompts,
                  isPlayable,
                  Workshop?.acronym,
                  Workshop?.team_name
                )
              )}
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Close</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to close the chat? This will erase the current
            conversation and start a new one.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onModalClose}>
              Cancel
            </Button>
            <Button variant="ghost" onClick={handleConfirmClose}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Chatbot;
