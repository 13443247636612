import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const InteractiveLoader = () => {
  const containerVariants = {
    animate: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const dotVariants = {
    animate: {
      y: [0, -20, 0],
      transition: {
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <Flex
      position="fixed"
      top="92px"
      left={0}
      right={0}
      bottom={0}
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 0, 0, 0.7)"
      zIndex={99}
      flexDirection="column"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        mb={4}
        as={motion.div}
        variants={containerVariants}
        animate="animate"
      >
        {[...Array(3)].map((_, index) => (
          <MotionBox
            key={index}
            width="10px"
            height="10px"
            borderRadius="50%"
            backgroundColor="blue.500"
            mx={2}
            variants={dotVariants}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default InteractiveLoader;