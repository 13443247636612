import React from "react";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import NavbarLogo from "../../../assets/game1/Logo.png";
import { RiNotification3Line } from "react-icons/ri";
import FullScreenDropdown from "views/systemadmin/FullScreen";
import UserProfileDropdown from "components/userprofile/userprofile";
import { useGameStore } from "store/store";
import { usePusher } from "context/pusher/PusherContext";
import { useLocation, useNavigate } from "react-router";
import axiosClient from "config/apiClient";

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { authData, Workshop } = useGameStore();
  const {
    Game2reset,
    setauthData,
    Game3reset,
    Game1reset,
    setPermissionsList,
  } = useGameStore();
  const navigate = useNavigate();
  const pusher = usePusher();
  const location = useLocation();
  const isPlayable = location.pathname.includes("play");
  const handleLogout = async () => {
    try {
      if (!isPlayable) {
        await axiosClient.post("/tenant/logout/", {
          refresh: authData.refresh,
        });
      } else {
        await axiosClient.post(
          `/workshop/logout/?acronym=${Workshop.acronym}`,
          {
            workshop_id: Workshop?.workshop_id,
            team_ids: [Workshop?.team?.id],
          }
        );
      }
      clearData();
    } catch (error) {
      clearData();
      throw error;
    }
    onClose();
  };

  function clearData() {
    {
      isPlayable
        ? navigate(`/play/${Workshop.acronym}`)
        : navigate("/auth/login");
    }
    {
      isPlayable
        ? localStorage.removeItem(`user_${Workshop?.team_name}_price`)
        : localStorage.removeItem(`user_${authData?.username}_price`);
    }
    pusher.disconnect.bind();
    setauthData(null);
    setPermissionsList(null);
    Game1reset();
    Game2reset();
    Game3reset();
  }

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 4px 10px 0px rgba(9, 56, 159, 0.25)"
      py={1}
      px={10}
    >
      <Box>
        <Image src={NavbarLogo} alt="Navbar Logo" h={10} w={16} />
      </Box>
      <HStack gap={4}>
        <FullScreenDropdown />
        <Icon as={RiNotification3Line} boxSize={6} cursor="pointer" />
        {/* <Icon as={FaRegCircleUser} boxSize={6} cursor="pointer" onClick={onOpen} /> */}
        {Workshop && isPlayable ? (
          <UserProfileDropdown
            username={Workshop?.team.name}
            teamname={`${Workshop?.team_name} team`}
            onLogout={handleLogout}
          />
        ) : (
          <UserProfileDropdown
            username={authData?.username}
            teamname={`${authData?.username} team`}
            onLogout={handleLogout}
          />
        )}
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logout Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to logout?</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button variant="ghost" onClick={handleLogout}>
              Logout
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
