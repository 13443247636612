import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  useToast,
} from "@chakra-ui/react";
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom";

interface Option {
  label: string;
  value: string;
}

interface FormField {
  label: string;
  id: string;
  type: "text" | "select" | "date" | "email";
  placeholder?: string;
  helperText: string;
  isRequired: boolean;
  options?: Option[];
  span: number;
}

const formFields: FormField[] = [
  {
    label: "Name",
    id: "name",
    type: "text",
    placeholder: "Enter the name",
    helperText: "",
    isRequired: true,
    span: 2,
  },
  {
    label: "User Name",
    id: "userName",
    type: "text",
    placeholder: "Enter the user name",
    helperText: "",
    isRequired: true,
    span: 2,
  },
  {
    label: "User Role",
    id: "userRole",
    type: "select",
    helperText: "Select the role of the user",
    isRequired: true,
    span: 2,
    options: [
      { label: "System", value: "system" },
      { label: "Admin", value: "admin" },
      { label: "Player", value: "player" },
    ],
  },
  {
    label: "Email",
    id: "email",
    placeholder: "Enter the email",
    type: "email",
    helperText: "",
    isRequired: true,
    span: 2,
  },
  {
    label: "Status",
    id: "status",
    type: "select",
    placeholder: "",
    helperText: "Select the staus of the user",
    isRequired: true,
    options: [
      { label: "Active", value: "active" },
      { label: "InActive", value: "inactive" },
    ],
    span: 2,
  },
  {
    label: "Select Client",
    id: "selectClient",
    type: "select",
    helperText: "Select the name of client",
    isRequired: true,
    span: 2,
    options: [
      { label: "ROAI", value: "roai" },
      { label: "Blueocean", value: "blueocean" },
      { label: "TFS", value: "tfs" },
      { label: "AST", value: "ast" },
      { label: "GCP", value: "gcp" },
      { label: "EKS", value: "eks" },
    ],
  },
];

type FormData = {
  [key: string]: string | Option | null;
};

type FormErrors = {
  [key: string]: string;
};

const CreateClientForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({});
  const [errors, setErrors] = useState<FormErrors>({});
  const navigate = useNavigate();
  const toast = useToast();

  const CustomMenu = (props: any) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <Button
          onClick={() =>
            navigate("/configuration/create", {
              state: { redirect: true },
            })
          }
          width="100%"
          bg="transparent"
          textColor="#0B389E"
          padding={2}
          cursor="pointer"
          borderRadius={0}
          borderTop="1px"
          borderColor="gray.300"
        >
          Create New Data
        </Button>
      </components.Menu>
    );
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: "20vh",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#2647B0" : state.isSelected ? "#2647B080" : provided.backgroundColor,
      color: state.isFocused ? "white" : provided.color,
      '&:hover': {
        backgroundColor: "#2647B0",
        color: "white",
      },
    }),
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    formFields.forEach((field) => {
      if (field.isRequired && !formData[field.id]) {
        newErrors[field.id] = `${field.label} is required`;
      }
    });

    // Additional validation for date-time fields
    if (formData.startDate && formData.endDate) {
      const startDate = new Date(formData.startDate as string);
      const endDate = new Date(formData.endDate as string);
      if (endDate <= startDate) {
        newErrors.endDate =
          "End date and time must be after the start date and time";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (): void => {
    if (validateForm()) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        toast({
          title: "Client created.",
          description: `${formData.clientName} has been successfully created with the selected game configurations.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        resetForm();
      }, 1500);
    } else {
      toast({
        title: "Error",
        description: "Please fill in all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const resetForm = (): void => {
    setFormData({});
    setErrors({});
  };

  const handleInputChange = (
    fieldId: string,
    value: string | Option | null
  ): void => {
    let formattedValue = value;
    setFormData((prev) => ({
      ...prev,
      [fieldId]: formattedValue,
    }));
    setErrors((prev) => ({
      ...prev,
      [fieldId]: "",
    }));
  };

  const renderField = (field: FormField): JSX.Element => {
    switch (field.type) {
      case "select":
        return (
          <Select<Option>
            id={`select-${field.id}`}
            classNamePrefix="select"
            name={field.id}
            isLoading={isLoading}
            isClearable={true}
            isSearchable={true}
            options={field.options}
            // components={{ Menu: CustomMenu }}
            styles={customStyles}
            value={formData[field.id] as Option | null}
            onChange={(selectedOption) =>
              handleInputChange(field.id, selectedOption)
            }
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        );
      case "date":
        return (
          <Input
            placeholder={field.placeholder}
            size="md"
            type="datetime-local"
            bg="InputBoxbg"
            value={(formData[field.id] as string) || ""}
            onChange={(e) => handleInputChange(field.id, e.target.value)}
            min={
              field.id === "endDate"
                ? (formData.startDate as string)
                : undefined
            }
            max={
              field.id === "startDate"
                ? (formData.endDate as string)
                : undefined
            }
            isDisabled={field.id === "endDate" && !formData.startDate}
          />
        );
      default:
        return (
          <Input
            placeholder={field.placeholder}
            bg="InputBoxbg"
            value={(formData[field.id] as string) || ""}
            onChange={(e) => handleInputChange(field.id, e.target.value)}
            _placeholder={{ color: "PlaceHolderColor" }}
          />
        );
    }
  };

  return (
    <Box
      id="addclient-form"
      overflowX="auto"
      borderRadius="20px"
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "18vw" }}
    >
      <Flex direction="column" flex={1}>
        <Flex
          bg="TableHeaderbg"
          justify="space-between"
          align="center"
          py="10px"
          px="20px"
          textColor="#0B389E"
          fontWeight={600}
          fontSize="18px"
        >
          Create User
        </Flex>
        <Flex
          bg="Containerbg"
          flex={1}
          py="10px"
          px="20px"
          direction="column"
          justifyContent="space-between"
        >
          <Flex id="form-box" gap={4} direction="column">
            <Grid templateColumns="repeat(2, 1fr)" gap={2}>
              {formFields.map((field) => (
                <GridItem key={field.id} colSpan={field.span}>
                  <FormControl
                    isRequired={field.isRequired}
                    isInvalid={!!errors[field.id]}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        [field.id]: "",
                      }));
                    }}
                  >
                    <FormLabel>{field.label}</FormLabel>
                    {renderField(field)}
                    <FormHelperText
                      color={errors[field.id] ? "red.500" : "inherit"}
                    >
                      {errors[field.id] || field.helperText}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
              ))}
            </Grid>
          </Flex>
          <Flex
            id="button-box"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap={3}
            mt={4}
          >
            <Button
              borderRadius="8px"
              bg="white"
              textColor="#0B389E"
              border="2px"
              borderColor="#5C85DC"
              py={2}
              px={4}
              onClick={resetForm}
            >
              Cancel
            </Button>
            <Button
              borderRadius="8px"
              bgGradient="linear(to-b, #5C86DC,#2647B0)"
              textColor="white"
              onClick={handleSubmit}
              isLoading={isLoading}
              py={2}
              px={4}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreateClientForm;
