
import {  Routes, Route, Navigate } from "react-router-dom";
import authRoutes from "auth-routes";
import "assets/css/App.css";
export default function Auth() {
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-screen w-full bg-color !bg-white dark:!bg-navy-900">
        <main className={`mx-auto h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex w-full flex-col justify-start pt-12 lg:h-screen lg:px-8 lg:pt-0 xl:h-[100vh] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:pl-0 xl:max-w-full">
                <Routes>
                  {getRoutes(authRoutes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
                
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
